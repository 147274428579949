import React from 'react';
import classNames from 'classnames';
import './Tab.scss';
import Badge from '../Badge/Badge';

export interface Props {
  id?: string;
  className?: string;
  children: string;
  value: string;
  count?: number;
  disabled?: boolean;
  isActive?: boolean;
  onClick: (value: string, e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {

}

class Tab extends React.Component<Props, State> {

  private action = React.createRef<HTMLButtonElement>();

  static defaultProps = {
    onClick: console.info,
  };

  getActionRef() {
    return this.action;
  }

  render() {
    const { isActive, disabled, value, count, onClick, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-tab', {
      'fourg-tab--disabled': disabled,
      'fourg-tab--active': isActive,
    }, className);
    return (
      <li className={containerClass} {...restProps}>
        <button
        ref={this.action}
        onClick={e => onClick(value, e)}
        className="fourg-tab__action"
        disabled={disabled}>
          <span className="fourg-tab__label">{children}</span>
          {(count !== undefined) && (
            <Badge>{count.toLocaleString()}</Badge>
          )}
        </button>
      </li>
    );
  }
}

export default Tab;
