import Table from '../Table';
import { TableOptions, TableLabels, TableEndpoints, TableField } from '../../types';
import Location from './Location';
import Company from './Company';
import NewBusinessTask from './tasks/NewBusinessTask';
import User from './User';

class Report extends Table {

  static options: TableOptions = {
    name: 'Report',
    slug: 'reports',
    labelKey: 'id',
    valueKey: 'id',
    lookupKey: 'reports',
    icon: 'assessment',
    defaultOrder: '-shipDate',
  };

  static labels: TableLabels = {
    description: 'All reports in the 4G Recycling cieTrade system.',
    pageTitle: 'Reports',
    singular: 'Report',
    plural: 'Reports',
    viewSingular: 'View report',
    viewPlural: 'View reports',
    selectSingular: 'Select report',
    selectPlural: 'Select reports',
    addSingular: 'Add report',
    addPlural: 'Add reports',
    editSingular: 'Edit report',
    editPlural: 'Edit reports',
    addedSingular: 'Report added',
    addedPlural: 'Reports added',
    updatedSingular: 'Report updated',
    updatedPlural: 'Reports updated',
    deletedSingular: 'Report deleted',
    deletedPlural: 'Reports deleted',
    archivedSingular: 'Report archived',
    archivedPlural: 'Reports archived',
    restoredSingular: 'Report restored',
    restoredPlural: 'Reports restored',
    errorFetchingSingular: 'Error fetching report',
    errorFetchingPlural: 'Error fetching reports',
    errorAddingSingular: 'Error adding report',
    errorAddingPlural: 'Error adding reports',
    errorUpdatingSingular: 'Error updating report',
    errorUpdatingPlural: 'Error updating reports',
    errorDeletingSingular: 'Error deleting report',
    errorDeletingPlural: 'Error deleting reports',
    errorArchivingSingular: 'Error archiving report',
    errorArchivingPlural: 'Error archiving reports',
    errorRestoringSingular: 'Error restoring report',
    errorRestoringPlural: 'Error restoring reports',
    notFoundSingular: 'Report not found',
    notFoundPlural: 'No reports found',
    loadingSingular: 'Loading report',
    loadingSingularEllipsis: 'Loading report...',
    loadingPlural: 'Loading reports',
    loadingPluralEllipsis: 'Loading reports...',
    search: 'Search reports',
    searchEllipsis: 'Search reports...',
    filter: 'Filter reports',
    settings: 'Reports Settings',
  };

  static endpoints: TableEndpoints = {
    readRecords: 'reports',
    readRecord: 'report/:id',
    createRecord: 'reports',
    updateRecord: 'report/:id',
    updateRecords: 'reports',
    patchRecord: 'report/:id',
    deleteRecord: 'report/:id',
    archiveRecord: 'report/:id/archive',
    restoreRecord: 'report/:id/restore',
  };

  static fields: TableField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'number',
      default: 0,
      isInfo: true,
      readOnly: true,
			infoSize: "small",
    },
		{
      name: 'RdID',
      label: 'Report ID',
      type: 'text',
      default: 0,
      isInfo: true,
      readOnly: true,
			infoSize: 'small',
    },
		{
      name: 'timeslice',
      label: 'Time Slice',
      type: 'text',
      default: '',
      readOnly: true,
      isSortable: true,
      isInfo: true,
      // isTableColumn: true,
			infoSize: 'small',
    },
		{
      name: 'companyId',
      label: 'Company',
      type: 'lookup-input',
      model: Company,
      default: '',
      isInfo: true,
			readOnly: true,
			isTableColumn: true,
			infoSize: 'medium',
    },
    {
      name: 'locationId',
      label: 'Location',
      type: 'lookup-input',
      model: Location,
      default: '',
      isInfo: true,
			readOnly: true,
			isTableColumn: true,
			infoSize: 'medium',
    },
		{
      name: 'taskId',
      label: 'Task',
      type: 'lookup-input',
      model: NewBusinessTask,
      default: '',
      isInfo: true,
			readOnly: true,
			// isTableColumn: true,
			infoSize: 'medium',
    },
		{
      name: 'assignedTo',
      label: 'Assignee',
      type: 'lookup-input',
      model: User,
      default: '',
      isInfo: true,
			readOnly: true,
			isTableColumn: true,
			infoSize: 'medium',
    },
		{
      name: 'estWeight',
      label: 'Est. Weight',
      type: 'number',
      default: 0,
      readOnly: true,
      isSortable: true,
      isInfo: true,
      // isTableColumn: true,
			infoSize: 'xsmall',
    },
		{
      name: 'estBuy',
      label: 'Est. Buy',
      type: 'currency',
      default: 0,
      readOnly: true,
      isSortable: true,
      isInfo: true,
      // isTableColumn: true,
			infoSize: 'xsmall',
    },
		{
      name: 'estSell',
      label: 'Est. Sell',
      type: 'currency',
      default: 0,
      readOnly: true,
      isSortable: true,
      isInfo: true,
      // isTableColumn: true,
			infoSize: 'xsmall',
    },
		{
      name: 'estProfit',
      label: 'Est. Profit',
      type: 'currency',
      default: 0,
      readOnly: true,
      isSortable: true,
      isInfo: true,
      isTableColumn: true,
			infoSize: 'xsmall',
    },
		{
      name: 'realWeight',
      label: 'Real. Weight',
      type: 'number',
      default: 0,
      readOnly: true,
      isSortable: true,
      isInfo: true,
      // isTableColumn: true,
			infoSize: 'xsmall',
    },
		{
      name: 'realBuy',
      label: 'Real. Buy',
      type: 'currency',
      default: 0,
      readOnly: true,
      isSortable: true,
      isInfo: true,
      // isTableColumn: true,
			infoSize: 'xsmall',
    },
		{
      name: 'realSell',
      label: 'Real. Sell',
      type: 'currency',
      default: 0,
      readOnly: true,
      isSortable: true,
      isInfo: true,
      // isTableColumn: true,
			infoSize: 'xsmall',
    },
		{
      name: 'realProfit',
      label: 'Real. Profit',
      type: 'currency',
      default: 0,
      readOnly: true,
      isSortable: true,
      isInfo: true,
      isTableColumn: true,
			infoSize: 'xsmall',
    },
		{
      name: 'variance',
      label: 'Profit Variance',
      type: 'currency-variance',
      default: 0,
      readOnly: true,
      isSortable: true,
      isInfo: true,
      isTableColumn: true,
			infoSize: 'medium',
    },
		{
      name: 'weightVariance',
      label: 'Weight Variance',
      type: 'number-variance',
      default: 0,
      readOnly: true,
      isSortable: true,
      isInfo: true,
      // isTableColumn: true,
			infoSize: 'medium',
    },
		{
      name: 'postDate',
      label: 'Posted',
      type: 'date',
      default: '',
      readOnly: true,
      isSortable: true,
      isInfo: true,
      // isTableColumn: true,
			infoSize: 'medium',
    },
		{
      name: 'shipDate',
      label: 'Shipped',
      type: 'date',
      default: '',
      readOnly: true,
      isSortable: true,
      isInfo: true,
      isTableColumn: true,
			infoSize: 'medium',
    },
    {
      name: 'createdAt',
      label: 'Created',
      type: 'date',
      default: '',
      readOnly: true,
      isSortable: true,
			isInfo: true,
			infoSize: 'medium',
    },
		{
      name: 'updatedAt',
      label: 'Updated',
      type: 'date',
      default: '',
      readOnly: true,
      isSortable: true,
			isInfo: true,
			infoSize: 'medium',
    },
		{
      name: 'worksheetNumbers',
      label: 'Worksheets',
      type: 'textarea',
      default: 0,
      readOnly: true,
			isInfo: true,
    },
  ];
}

export default Report;

