import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router-dom';
import CloseToast from '../../components/CloseToast/CloseToast';
import Page from '../../components/Page/Page';
import Button from '../../components/Button/Button';
import FilterBar from '../../components/FilterBar/FilterBar';
import Table from '../../components/Table/Table';
import TableCellByField from '../../components/TableCellByField/TableCellByField';
import Pagination from '../../components/Pagination/Pagination';
import Company from '../../models/tables/Company';
import { CompanySchema, Auth, Socket, SocketMessage } from '../../types';
import CompanyFormDialog from '../../components/CompanyFormDialog/CompanyFormDialog';
import './CompaniesPage.scss';

export interface RouteParams {

}

export interface Props extends RouteComponentProps<RouteParams> {
  id?: string;
  auth: Auth;
  socket: Socket;
  className?: string;
}

export interface State {
  isLoading: boolean;
  isUpdating: boolean;
  isCreateDialogOpen: boolean;
  records: CompanySchema[];
  total: number;
}

class CompaniesPage extends React.Component<Props, State> {

  private createDialog = React.createRef<CompanyFormDialog>();

  constructor(props: Props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleLimitChange = this.handleLimitChange.bind(this);
    this.handleOrderChange = this.handleOrderChange.bind(this);
    this.handleTableRowDoubleClick = this.handleTableRowDoubleClick.bind(this);
    this.handleFormDialogClose = this.handleFormDialogClose.bind(this);
    this.handleFormDialogSubmit = this.handleFormDialogSubmit.bind(this);
    this.handleCreateDialogSubmit = this.handleCreateDialogSubmit.bind(this);
    this.handleCreateDialogSecondary = this.handleCreateDialogSecondary.bind(this);
    this.handleCreateDialogClose = this.handleCreateDialogClose.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleCreateClick = this.handleCreateClick.bind(this);
    this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind(this);
    this.handleSocketMessage = this.handleSocketMessage.bind(this);
    this.state = {
      records: [],
      total: 0,
      isLoading: true,
      isUpdating: false,
      isCreateDialogOpen: false,
    };
  }

  componentDidMount() {
    this.readRecords();
  }

  componentDidUpdate(prevProps: Props) {
    const { location } = this.props;
    if ((this.getPage(prevProps.location) !== this.getPage(location))
    || (this.getLimit(prevProps.location) !== this.getLimit(location))
    || (this.getOrder(prevProps.location) !== this.getOrder(location))
    || (this.getSearch(prevProps.location) !== this.getSearch(location))) {
      this.readRecords();
    }
  }

  componentWillUnmount() {
    this.socketDisconnect();
		toast.dismiss('update-list');
  }

  socketConnect() {
    const { socket } = this.props;
		if (socket.current?.readyState === WebSocket.OPEN) {
			socket.current?.send(JSON.stringify({ action: `companies:connect` }));
		}
		window.addEventListener('fourg:socket-message', this.handleSocketMessage);
  }

  socketDisconnect() {
    const { socket } = this.props;
		if (socket.current?.readyState === WebSocket.OPEN) {
			socket.current?.send(JSON.stringify({ action: `companies:disconnect` }));
		}
		window.removeEventListener('fourg:socket-message', this.handleSocketMessage);
  }

  isSocketMessageValid(socketMessage?: SocketMessage) {
    const { auth } = this.props;
    let isValid = false;
    if (socketMessage) {
      const isStreamValid = ['companies'].includes(socketMessage.stream);
      const isCreatedByUser = (socketMessage.data.createdBy === auth.user?.id);
      isValid = (isStreamValid && ! isCreatedByUser);
    }
    return isValid;
  }

  handleSocketMessage(e: CustomEventInit<SocketMessage>) {
    if (this.isSocketMessageValid(e.detail)) {
      toast.info('There have been updates to the list', {
        toastId: 'update-list',
        autoClose: false,
        hideProgressBar: true,
        draggable: false,
        onClose: () => this.readRecords(),
        closeButton: ( <CloseToast icon={{ icon: 'refresh' }} label={'Reload'} /> ),
      });
    }
  }

  getRecordByID(id: CompanySchema['id']) {
    const { records } = this.state;
    return records.find(record => record.id === id);
  }

  async readRecords(isInitialLoad: boolean = true) {
    const { location, auth, socket } = this.props;
    this.setState({ isLoading: true });
    try {
      const token = await auth.getToken();
      const { meta, data } = await Company.readRecords<CompanySchema>(token, {
        page: this.getPage(location),
        limit: this.getLimit(location),
        order: this.getOrder(location),
        search: this.getSearch(location),
      });
      this.setState({
        isLoading: false,
        records: data,
        total: meta.total,
      });
      if (isInitialLoad && (socket.current?.readyState === WebSocket.OPEN)) {
        this.socketConnect();
      }
    } catch(error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({
        isLoading: false,
        records: [],
      });
    }
  }

  async createRecord(record: CompanySchema, isCreateDialogOpen: boolean = false) {
    const { auth } = this.props;
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      await Company.createRecord<CompanySchema>(token, record);
      toast.success(Company.getLabel('addedSingular'));
      this.setState({
        isUpdating: false,
        isCreateDialogOpen: isCreateDialogOpen,
      });
      this.readRecords();
      const createDialog = this.createDialog.current;
      createDialog?.setDefaultRecord();
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  async updateRecord(id: string, record: CompanySchema) {
    const { location, history, auth } = this.props;
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      const { data } = await Company.updateRecord<CompanySchema>(token, id, record);
      toast.success(Company.getLabel('updatedSingular'));
      this.syncRecord(data);
      const params = new URLSearchParams(location.search);
      params.delete('record');
      this.setState({ isUpdating: false });
      history.push(`${location.pathname}?${params.toString()}`);
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  syncRecord(newRecord: CompanySchema) {
    const { records } = this.state;
    if (records) {
      this.setState({
        records: records.map(record => {
          return (record.id === newRecord.id) ? newRecord : record;
        }),
      });
    }
  }

  getPage(location: RouteComponentProps['location']) {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    return page ? parseInt(page, 10) : 1;
  }

  getLimit(location: RouteComponentProps['location']) {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit');
    return limit ? parseInt(limit, 10) : 20;
  }

  getOrder(location: RouteComponentProps['location']) {
    const { defaultOrder } = Company.getOptions();
    const params = new URLSearchParams(location.search);
    return params.get('order') || defaultOrder;
  }

  getSearch(location: RouteComponentProps['location']) {
    const params = new URLSearchParams(location.search);
    return params.get('search') || undefined;
  }

  handlePageChange(page: number) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('page', page.toString());
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleLimitChange(limit: number) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('limit', limit.toString());
    params.delete('page');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleOrderChange(order: string) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('order', order);
    params.delete('page');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleTableRowDoubleClick(record: CompanySchema) {
    const { history,location } = this.props;
    history.push(`${location.pathname}/${record.id}`);
  }

  handleFormDialogClose() {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.delete('record');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleSearchChange(search: string) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    if (search) {
      params.set('search', search);
    } else {
      params.delete('search');
    }
    params.delete('page');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleCreateDialogClose() {
    this.setState({ isCreateDialogOpen: false });
  }

  handleCreateDialogSubmit(record: CompanySchema) {
    this.createRecord(record);
  }

  handleCreateDialogSecondary(record: CompanySchema) {
    this.createRecord(record, true);
  }

  handleCreateClick() {
    this.setState({ isCreateDialogOpen: true });
  }

  handleFormDialogSubmit(record: CompanySchema, e: React.MouseEvent<HTMLButtonElement>) {
    this.updateRecord(record.id.toString(), record);
  }

  handleRefreshButtonClick() {
    this.readRecords();
  }

  render() {
    const { socket, className, match, location, history, staticContext, auth, ...restProps } = this.props;
    const { records, total, isLoading, isUpdating, isCreateDialogOpen } = this.state;
    const containerClass = classNames('fourg-companies-page', className);
    const companyOptions = Company.getOptions<CompanySchema>();
    const page = this.getPage(location);
    const limit = this.getLimit(location);
    const startNumber = (((page * limit) - limit) + 1);
    let endNumber = ((startNumber + limit) - 1);
    endNumber = (endNumber <= total) ? endNumber : total;
    return (
      <Page
      title={Company.getLabel('pageTitle')}
      description={Company.getLabel('description')}
      headerDescription={isLoading ? Company.getLabel('loadingPluralEllipsis') : `Viewing ${(total > 0) ? `${startNumber}-${endNumber} of ` : ''}${total} ${Company.getLabel('plural')}.`}
      className={containerClass}
      {...restProps}>
        <FilterBar
        searchLabel={Company.getLabel('searchEllipsis')}
        searchValue={this.getSearch(location)}
        onRefreshButtonClick={this.handleRefreshButtonClick}
        onSearchChange={this.handleSearchChange}>
          <Button
          icon={{ icon: 'add' }}
          variant="raised"
          onClick={this.handleCreateClick}>
            {Company.getLabel('addSingular')}
          </Button>
        </FilterBar>
        <Table<CompanySchema>
        isScrollable={true}
        columns={Company.getFieldColumns<CompanySchema>()}
        records={records}
        order={this.getOrder(location)}
        isLoading={isLoading}
        onOrderChange={this.handleOrderChange}
        onRowDoubleClick={this.handleTableRowDoubleClick}
        notFoundIcon={{ icon: companyOptions.icon }}
        notFoundHeading={Company.getLabel('notFoundPlural')}
        renderCell={(value, column, record) => {
          const field = Company.getField<CompanySchema>(column.key);
          return (! field) ? value.toString() : (
            <TableCellByField<CompanySchema>
            field={field}
            value={value}
            record={record} />
          );
        }} />
        <Pagination
        disabled={(isLoading || isUpdating)}
        page={page}
        limit={limit}
        total={total}
        onPageChange={this.handlePageChange}
        onLimitChange={this.handleLimitChange} />
        <CompanyFormDialog
        disabled={isLoading || isUpdating}
        auth={auth}
        ref={this.createDialog}
        title={Company.getLabel('addSingular')}
        isOpen={isCreateDialogOpen}
        submitLabel={'Save'}
        secondaryLabel={'Save and Add Another'}
        cancelLabel={'Cancel'}
        onFormSubmit={this.handleCreateDialogSubmit}
        onFormSecondary={this.handleCreateDialogSecondary}
        onCloseClick={this.handleCreateDialogClose}
        // onBackdropClick={this.handleCreateDialogClose}
        onFormCancel={this.handleCreateDialogClose}
        onEscape={this.handleCreateDialogClose} />
      </Page>
    );
  }
}

export default CompaniesPage;
