import mime from 'mime';
import Table from '../Table';
import { TableOptions, TableLabels, TableEndpoints, TableField, UserSchema, UserSettings, TableFormSection, TableFormPage, AttachmentSchema, CreateAttachmentResponse/* , TaskSchema */ } from '../../types';
import { APIError } from '../../errors';
import Attachment from './Attachment';
// import Task from './Task';

class User extends Table {

  static options: TableOptions = {
    name: 'User',
    slug: 'users',
    labelKey: 'email',
    valueKey: 'id',
    imageKey: 'avatar',
    lookupKey: 'users',
    icon: 'account_circle',
    defaultOrder: 'first',
  };

  static labels: TableLabels = {
    description: 'All users given access to the 4G Recycling Site Manager.',
    pageTitle: 'User Manager',
    singular: 'User',
    plural: 'Users',
    viewSingular: 'View User',
    viewPlural: 'View Users',
    selectSingular: 'Select User',
    selectPlural: 'Select Users',
    addSingular: 'Add User',
    addPlural: 'Add Users',
    editSingular: 'Edit User',
    editPlural: 'Edit Users',
    addedSingular: 'User added',
    addedPlural: 'Users added',
    updatedSingular: 'User updated',
    updatedPlural: 'Users updated',
    deletedSingular: 'User deleted',
    deletedPlural: 'Users deleted',
    archivedSingular: 'User archived',
    archivedPlural: 'Users archived',
    restoredSingular: 'User restored',
    restoredPlural: 'Users restored',
    errorFetchingSingular: 'Error fetching User',
    errorFetchingPlural: 'Error fetching Users',
    errorAddingSingular: 'Error adding User',
    errorAddingPlural: 'Error adding Users',
    errorUpdatingSingular: 'Error updating User',
    errorUpdatingPlural: 'Error updating Users',
    errorDeletingSingular: 'Error deleting User',
    errorDeletingPlural: 'Error deleting Users',
    errorArchivingSingular: 'Error archiving User',
    errorArchivingPlural: 'Error archiving Users',
    errorRestoringSingular: 'Error restoring User',
    errorRestoringPlural: 'Error restoring Users',
    notFoundSingular: 'User not found',
    notFoundPlural: 'No Users found',
    loadingSingular: 'Loading User',
    loadingSingularEllipsis: 'Loading User...',
    loadingPlural: 'Loading Users',
    loadingPluralEllipsis: 'Loading Users...',
    search: 'Search Users',
    searchEllipsis: 'Search Users...',
    filter: 'Filter Users',
    settings: 'Users Settings',
  };

  static endpoints: TableEndpoints = {
    readRecords: 'users',
    readRecord: 'user/:id',
    createRecord: 'user',
    updateRecord: 'user/:id',
    updateRecords: 'users',
    patchRecord: 'user/:id',
    deleteRecord: 'user/:id',
    archiveRecord: 'user/:id/archive',
    restoreRecord: 'user/:id/restore',
  };

  static formPages: TableFormPage[] = [
    {
      name: 'profile',
      label: 'Profile',
      icon: { icon: 'account_circle' },
    },
    {
      name: 'settings',
      label: 'Settings',
      icon: { icon: 'settings' },
    },
  ];

  static formSections: TableFormSection[] = [
    {
      name: 'profile-image',
      label: 'Profile Image',
      page: 'profile',
    },
    {
      name: 'edit-profile',
      label: 'Edit Profile',
      page: 'profile'
    },
    {
      name: 'roles',
      label: 'Roles',
      page: 'settings',
    },
    {
      name: 'notifications',
      label: 'Notifications',
      page: 'settings',
    },
    {
      name: 'application',
      label: 'Application',
      page: 'settings',
    },
  ];

  static fields: TableField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'text',
      default: '',
      readOnly: true,
    },
    {
      name: 'first',
      label: 'First Name',
      type: 'text',
      default: '',
      required: true,
      isSortable: true,
      isFormField: true,
      isTableColumn: true,
      formSection: 'edit-profile',
    },
    {
      name: 'last',
      label: 'Last Name',
      type: 'text',
      default: '',
      required: true,
      isSortable: true,
      isFormField: true,
      isTableColumn: true,
      formSection: 'edit-profile',
    },
    {
      name: 'title',
      label: 'Title',
      type: 'text',
      default: '',
      isSortable: true,
      isFormField: true,
      isTableColumn: true,
      formSection: 'edit-profile',
    },
    {
      name: 'email',
      label: 'Email',
      type: 'email',
      default: '',
      required: true,
      isSortable: true,
      isFormField: true,
      isTableColumn: true,
      formSection: 'edit-profile',
    },
    {
      name: 'admin',
      label: 'Administrator',
      type: 'checkbox',
      default: false,
      isTableColumn: true,
      isFormField: true,
      isInfo: true,
      infoSize: 'medium',
      formSection: 'roles',
    },
    {
      name: 'created',
      label: 'Created',
      type: 'date',
      default: '',
      readOnly: true,
      isSortable: true,
    },
    {
      name: 'updated',
      label: 'Updated',
      type: 'date',
      default: '',
      readOnly: true,
      isSortable: true,
    }
  ];

  static getDefaultSettings() {
    const defaultSettings: UserSettings = {
      notifications: {
        email: true,
        createdTasks: false,
        createdReminders: false,
      },
      application: {
        defaultBoard: '',
        defaultDashboardSection: '',
        defaultQuickViewSection: '',
        defaultBoardView: '',
        hideCompletedOnDashboard: false,
        rememberBoardGroup: false,
      },
    };
    return defaultSettings;
  }

  static getRecordLabel(record: any) {
    return `${record.first} ${record.last}`;
  }

  static async readSettings(token: string, id: string, init?: RequestInit): Promise<UserSettings> {
    const url = `${this.getEndpoint('readRecord').replace(':id', id)}/settings`;
    const response = await fetch(url, {
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
				...init,
    });
    switch(response.status) {
        case 200: return await response.json();
        default: throw new APIError(response.status, 'Error fetching user settings');
    }
  }

  static async forcePasswordReset(token: string, id: string, password: string): Promise<string> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id)}/force-password`, {
        method: 'POST',
        body: JSON.stringify({ pass: password }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    });
    switch (response.status) {
        case 200: return password;
        default: throw new APIError(response.status, this.getLabel('errorUpdatingSingular'));
    }
}

  static async updateSettings(token: string, id: string, settings: UserSettings): Promise<UserSettings> {
    const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id)}/settings`, {
        method: 'POST',
        body: JSON.stringify(settings),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
    });
    switch (response.status) {
        case 200: return await response.json();
        default: throw new APIError(response.status, this.getLabel('errorUpdatingSingular'));
    }
}

  static async createAvatar(token: string, id: UserSchema['id'], record: AttachmentSchema): Promise<string> {
    const file = record.file;
    if (file) {
      const response = await fetch(`${this.getEndpoint('updateRecord').replace(':id', id.toString())}/add-avatar`, {
        method: 'POST',
        body: JSON.stringify({
          title: record.title,
          description: record.description,
          contentType: file.type || mime.getType(file.name) || 'application/octet-stream',
          fileName: file.name,
        }),
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      switch (response.status) {
        case 200:
          const { signedUrl, id: attachmentID, filePath }: CreateAttachmentResponse = await response.json();
          await Attachment.uploadFile(attachmentID, signedUrl, file);
          await Attachment.validateUpload(token, attachmentID);
          return this.getS3URL(filePath);
        default: throw new APIError(response.status, Attachment.getLabel('errorAddingSingular'));
      }
    } else {
      throw new Error('no file');
    }
  }
}

export default User;
