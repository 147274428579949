import React from 'react';
import classNames from 'classnames';
import DateSpan from '../DateSpan/DateSpan';
import { ReportSchema } from '../../types';
import './ReportShipDateCell.scss';

export interface Props {
	id?: string;
	className?: string;
	record: ReportSchema;
}

export interface State {

}

class ReportShipDateCell extends React.Component<Props, State> {
	render() {
		const {record, className, ...restProps} = this.props;
		const containerClass = classNames('fourg-report-ship-date-cell', className);
		return (
			<div className={containerClass} {...restProps}>
				<span>
					{"Time slice: "}
					<code>{record.timeslice}</code>
				</span>
				<small>
					{'Shipped on '}
					<DateSpan date={new Date(record.shipDate)} />
				</small>
			</div>
		);
	}
}

export default ReportShipDateCell;
