import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router-dom';
import Page from '../../components/Page/Page';
import FilterBar from '../../components/FilterBar/FilterBar';
import Table from '../../components/Table/Table';
import TableCellByField from '../../components/TableCellByField/TableCellByField';
import Pagination from '../../components/Pagination/Pagination';
import ReportDetailDialog from '../../components/ReportDetailDialog/ReportDetailDialog';
import Report from '../../models/tables/Report';
import { AttachmentSchema, Auth, CompanySchema, ContactSchema, ExpenseSchema, GradeSchema, LocationSchema, Lookup, ReminderSchema, ReportSchema, Socket, TaskSchema, UIOption, UserSchema } from '../../types';
import { mergeRecords } from '../../utils';
import { LookupContext } from '../../contexts';
import './ReportsPage.scss';
import ReportShipDateCell from '../../components/ReportShipDateCell/ReportShipDateCell';

export interface RouteParams {

}

export interface Props extends RouteComponentProps<RouteParams> {
  id?: string;
  auth: Auth;
	socket: Socket;
  className?: string;
}

export interface State {
  isLoading: boolean;
  records: ReportSchema[];
  total: number;
	lookup: Lookup;
	selectedReport?: ReportSchema;
}

class ReportsPage extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleLimitChange = this.handleLimitChange.bind(this);
    this.handleOrderChange = this.handleOrderChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleRefreshButtonClick = this.handleRefreshButtonClick.bind(this);
		this.handleRowDoubleClick = this.handleRowDoubleClick.bind(this);
		this.handleReportActionChange = this.handleReportActionChange.bind(this);
		this.getReportActions = this.getReportActions.bind(this);
		this.handleReportDetailsDialogClose = this.handleReportDetailsDialogClose.bind(this);
    this.state = {
      records: [],
      total: 0,
      isLoading: true,
			lookup: {},
			selectedReport: undefined,
    };
  }

  componentDidMount() {
    this.readRecords();
  }

  componentDidUpdate(prevProps: Props) {
    const { location } = this.props;
    if ((this.getPage(prevProps.location) !== this.getPage(location))
    || (this.getLimit(prevProps.location) !== this.getLimit(location))
    || (this.getOrder(prevProps.location) !== this.getOrder(location))
    || (this.getSearch(prevProps.location) !== this.getSearch(location))) {
      this.readRecords();
    }
  }

  async readRecords(isInitialLoad: boolean = true) {
    const { location, auth } = this.props;
		const { lookup } = this.state;
    this.setState({ isLoading: true });
    try {
      const token = await auth.getToken();
      const { meta, data } = await Report.readRecords<ReportSchema>(token, {
        page: this.getPage(location),
        limit: this.getLimit(location),
        order: this.getOrder(location),
        search: this.getSearch(location),
      });
      this.setState({
        isLoading: false,
        records: data,
        total: meta.total,
				lookup: {
          ...lookup,
					users: mergeRecords<UserSchema>(meta.users, lookup.users),
					companies: mergeRecords<CompanySchema>(meta.companies, lookup.companies),
					locations: mergeRecords<LocationSchema>(meta.locations, lookup.locations),
					tasks: mergeRecords<TaskSchema>(meta.tasks, lookup.tasks),
					attachments: mergeRecords<AttachmentSchema>(meta.attachments, lookup.attachments),
					reminders: mergeRecords<ReminderSchema>(meta.reminders, lookup.reminders),
					grades: mergeRecords<GradeSchema>(meta.grades, lookup.grades),
					expenses: mergeRecords<ExpenseSchema>(meta.expenses, lookup.expenses),
					contacts: mergeRecords<ContactSchema>(meta.contacts, lookup.contacts),
        },
      });
    } catch(error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({
        isLoading: false,
        records: [],
				lookup: {},
				total: 0,
      });
    }
  }

  getPage(location: RouteComponentProps['location']) {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    return page ? parseInt(page, 10) : 1;
  }

  getLimit(location: RouteComponentProps['location']) {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit');
    return limit ? parseInt(limit, 10) : 20;
  }

  getOrder(location: RouteComponentProps['location']) {
    const { defaultOrder } = Report.getOptions();
    const params = new URLSearchParams(location.search);
    return params.get('order') || defaultOrder;
  }

  getSearch(location: RouteComponentProps['location']) {
    const params = new URLSearchParams(location.search);
    return params.get('search') || undefined;
  }

  handlePageChange(page: number) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('page', page.toString());
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleLimitChange(limit: number) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('limit', limit.toString());
    params.delete('page');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleOrderChange(order: string) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('order', order);
    params.delete('page');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleSearchChange(search: string) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    if (search) {
      params.set('search', search);
    } else {
      params.delete('search');
    }
    params.delete('page');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleRefreshButtonClick() {
    this.readRecords();
  }

	handleRowDoubleClick(record: ReportSchema) {
		this.setState({ selectedReport: record });
	}

	getReportActions(record: ReportSchema): UIOption[] {
		return [
			{ label: 'View Details', value: 'view-details' },
			{ label: 'View Company', value: 'view-company' },
			{ label: 'View Location', value: 'view-location' },
			{ label: 'View Task', value: 'view-task' },
		];
	}

	handleReportActionChange(value: UIOption['value'], record: ReportSchema) {
		const { history } = this.props;
		switch (value) {
			case 'view-details':
				this.setState({ selectedReport: record });
				break;
			case 'view-company':
				history.push(`/companies/${record.companyId}`);
				break;
			case 'view-location':
				history.push(`/companies/${record.companyId}/locations/${record.locationId}`);
				break;
			case 'view-task':
				history.push(`/tasks/${record.taskId}`);
				break;
			default:
				console.error(`No report row action configured for value "${value}"`);
				break;
		}
	}

	handleReportDetailsDialogClose() {
		this.setState({ selectedReport: undefined });
	}

  render() {
    const { className, match, location, history, staticContext, auth, socket, ...restProps } = this.props;
    const { records, total, isLoading, lookup, selectedReport } = this.state;
    const containerClass = classNames('fourg-reports-page', className);
    const reportModelOptions = Report.getOptions<ReportSchema>();
    const page = this.getPage(location);
    const limit = this.getLimit(location);
    const startNumber = (((page * limit) - limit) + 1);
    let endNumber = ((startNumber + limit) - 1);
    endNumber = (endNumber <= total) ? endNumber : total;
    return (
			<LookupContext.Provider value={lookup}>
				<Page
				title={Report.getLabel('pageTitle')}
				description={Report.getLabel('description')}
				headerDescription={isLoading ? Report.getLabel('loadingPluralEllipsis') : `Viewing ${(total > 0) ? `${startNumber}-${endNumber} of ` : ''}${total} ${Report.getLabel('plural')}.`}
				className={containerClass}
				{...restProps}>
					<FilterBar
					isSearchDisabled={true /* TODO: Remove this when reports search is implemented */}
					searchLabel={Report.getLabel('searchEllipsis')}
					searchValue={this.getSearch(location)}
					onRefreshButtonClick={this.handleRefreshButtonClick}
					onSearchChange={this.handleSearchChange} />
					<Table<ReportSchema>
					isScrollable={true}
					columns={Report.getFieldColumns<ReportSchema>()}
					records={records}
					order={this.getOrder(location)}
					isLoading={isLoading}
					onOrderChange={this.handleOrderChange}
					notFoundIcon={{ icon: reportModelOptions.icon }}
					notFoundHeading={Report.getLabel('notFoundPlural')}
					onRowDoubleClick={this.handleRowDoubleClick}
					getRowActions={this.getReportActions}
					onRowActionsChange={this.handleReportActionChange}
					renderCell={(value, column, record) => {
						switch (column.key) {
							case 'shipDate': {
								return (
									<ReportShipDateCell record={record as ReportSchema} />
								);
							}
							default: {
								const field = Report.getField<ReportSchema>(column.key);
								return (! field) ? value.toString() : (
									<TableCellByField<ReportSchema>
									field={field}
									value={value}
									record={record as ReportSchema} />
								);
							}
						}
					}} />
					<Pagination
					disabled={isLoading}
					page={page}
					limit={limit}
					total={total}
					onPageChange={this.handlePageChange}
					onLimitChange={this.handleLimitChange} />
				</Page>
				<ReportDetailDialog
				title={Report.getLabel('singular')}
				record={selectedReport}
				isOpen={Boolean(selectedReport)}
				onCloseClick={this.handleReportDetailsDialogClose}
				onBackdropClick={this.handleReportDetailsDialogClose}
				onEscape={this.handleReportDetailsDialogClose} />
			</LookupContext.Provider>
    );
  }
}

export default ReportsPage;
