import React from 'react';
import classNames from 'classnames';
import TimeAgo from 'react-timeago';
import './DateSpan.scss';

export interface Props {
  id?: string;
  className?: string;
  date: Date;
  hasTime?: boolean;
	hasTimeAgo?: boolean;
}

export interface State {

}

class DateSpan extends React.Component<Props, State> {

  getDateLabel() {
    const { date, hasTime } = this.props;
    const now = new Date();
    if (date.getFullYear() === now.getFullYear()) {
      return date.toLocaleString(undefined, {
        month: 'short',
        day: 'numeric',
        hour: hasTime ? 'numeric' : undefined,
        minute: hasTime ? '2-digit' : undefined,
      });
    } else {
      return date.toLocaleString(undefined, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: hasTime ? 'numeric' : undefined,
        minute: hasTime ? '2-digit' : undefined,
      });
    }
  }

  render() {
    const { hasTime, hasTimeAgo, date, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-date-span', className);
    return (
      <span className={containerClass} {...restProps}>
        <time dateTime={date.toISOString()} title={date.toLocaleString()}>
					{this.getDateLabel()}
				</time>
				{hasTimeAgo && (
					<React.Fragment>
						{' ('}
						<TimeAgo
						date={date}
						title={date.toLocaleString()} />
						{')'}
					</React.Fragment>
				)}
      </span>
    );
  }
}

export default DateSpan;
