import React from 'react';
import classNames from 'classnames';
import Icon, { Props as IconProps } from '../Icon/Icon';
import './VarianceSpan.scss';

export interface Props {
	id?: string;
	className?: string;
	value: number;
	isCurrency?: boolean;
}

export interface State {

}

class VarianceSpan extends React.Component<Props, State> {

	getVariance(): '-' | '+' | '=' {
		const { value } = this.props;
		if ( value > 0 ) return '+';
		if ( value < 0 ) return '-';
		return '=';
	}

	getLabel(): string {
		const variance = this.getVariance();
		switch (variance) {
			case '+': return 'Positive';
			case '-': return 'Negative';
			default: return 'Equal';
		}
	}

	getIcon(): IconProps['src'] {
		const variance = this.getVariance();
		switch (variance) {
			case '+': return {
				icon: 'trending_up',
			};
			case '-': return {
				icon: 'trending_down',
			};
			default: return {
				icon: 'trending_flat',
			};
		}
	}

	render() {
		const { isCurrency, className, value, children, ...restProps } = this.props;
		const variance = this.getVariance();
		const containerClass = classNames('fourg-variance-span', {
			'fourg-variance-span--positive': (variance === '+'),
			'fourg-variance-span--negative': (variance === '-'),
		}, className);
		const label = this.getLabel();
		const icon = this.getIcon();
		return (
			<span className={containerClass} {...restProps}>
				<Icon src={icon} label={label} />
				{isCurrency ? (
					<span className="fourg-variance-span__label">
						{value.toLocaleString(undefined, { style: 'currency', currency: 'USD' })}
					</span>
				) : (
					<span className="fourg-variance-span__label">
						{value.toLocaleString()}
					</span>
				)}
			</span>
		);
	}
}

export default VarianceSpan;
