import Task from '../Task';
import User from '../User';
import Company from '../Company';
import Location from '../Location';
import { TableOptions, TableField, TableFilter, TaskSchema, CompanySchema, LocationSchema, CieTradeCounterpartySchema, CieTradeLocationSchema } from '../../../types';
import { APIError } from '../../../errors';

class NewBusinessTask extends Task {

  static options: TableOptions = {
    name: 'NewBusinessTask',
    slug: 'tasks',
    labelKey: 'title',
    valueKey: 'id',
    lookupKey: 'tasks',
    icon: 'assignment',
    defaultOrder: '-created',
  };

  static filters: TableFilter[] = [
    {
      name: 'updated',
      label: 'Task Activity',
      type: 'radio',
      options: [
        { value: '', label: 'Any time'},
        { value: 'today', label: 'Updated today' },
        { value: 'week', label: 'One week or less' },
        { value: 'month', label: 'One month or less' },
        { value: 'older', label: 'More than one month' },
      ],
    },
    {
      name: 'age',
      label: 'Task Age',
      type: 'radio',
      options: [
        { value: '', label: 'Any Age'},
        { value: 'today', label: 'Added today' },
        { value: 'week', label: 'One week or less' },
        { value: 'month', label: 'One month or less' },
        { value: 'older', label: 'Older than one month' },
      ],
    },
    {
      name: 'due',
      label: 'Due Date',
      type: 'radio',
      options: [
        { value: '', label: 'Any due date' },
        { value: 'overdue', label: 'Overdue' },
        { value: 'today', label: 'Due today' },
        { value: 'week', label: 'Due this week' },
        { value: 'month', label: 'Due this month' },
      ],
    },
    {
      name: 'priority',
      label: 'Priority',
      type: 'radio',
      options: [
        { value: '', label: 'Any Priority'},
        { value: 'low', label: 'Low priority' },
        { value: 'medium', label: 'Medium priority' },
        { value: 'high', label: 'High priority' },
      ],
    },
    {
      name: 'status',
      label: 'Status',
      type: 'radio',
      options: [
        { label: 'Any Status', value: '' },
        { label: 'Leads', value: 'leads' },
        { label: 'Qualified', value: 'qualified' },
        { label: 'Proposal', value: 'proposal' },
        { label: 'Under Review', value: 'under-review' },
        { label: 'On Hold', value: 'on-hold' },
        { label: 'Closed (Won)', value: 'closed-won' },
        { label: 'Closed (Lost)', value: 'closed-lost' },
        { label: 'Unqualified', value: 'unqualified' },
      ],
    },
    {
      name: 'category',
      label: 'Category',
      type: 'radio',
      options: [
        { label: 'Any Category', value: '' },
        { label: 'Uncategorized', value: 'uncategorized' },
        { label: 'Mill Direct', value: 'mill-direct' },
        { label: 'Recycling', value: 'recycling' },
        { label: 'Trash', value: 'trash' },
        { label: 'Grocery Retail', value: 'grocery-retail' },
				{ label: 'Non-grocery Retail', value: 'non-grocery-retail' },
				{ label: 'Printing', value: 'printing' },
        { label: 'Manufacturing', value: 'manufacturing' },
        { label: 'Converting', value: 'converting' },
      ],
    },
    {
      name: 'assignee',
      label: 'Assignee',
      type: 'lookup-input',
      model: User,
      options: [
        { value: '', label: 'Any Assignee'},
        { value: 'unassigned', label: 'Unassigned', icon: { icon: 'person_outline' } },
        { value: 'assigned-to-me', label: 'Assigned to Me', icon: { icon: 'person' } },
      ],
    },
    {
      name: 'creator',
      label: 'Creator',
      type: 'lookup-input',
      model: User,
      options: [
        { value: '', label: 'Any Creator'},
        { value: 'created-by-me', label: 'Created by Me', icon: { icon: 'person' } },
      ],
    },
    {
      name: 'following',
      label: 'Following',
      type: 'radio',
      options: [
        { value: '', label: 'Any'},
        { value: 'true', label: 'Following' },
        { value: 'false', label: 'Not following' },
      ],
    },
    {
      name: 'archived',
      label: 'Archived',
      type: 'radio',
      options: [
        { value: '', label: 'Unarchived' },
        { value: 'true', label: 'Archived' },
      ],
    },
  ];

  static fields: TableField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'number',
      default: 0,
      readOnly: true,
      isInfo: true,
    },
    {
      name: 'title',
      label: 'Title',
      type: 'text',
      default: '',
      required: true,
      isSortable: true,
      isInfo: true,
			isTableColumn: true,
    },
    {
      name: 'companyId',
      label: 'Company',
      type: 'lookup',
      model: Company,
      options: [
        { label: 'Unassigned', value: '' },
      ],
      default: '',
      isFormField: true,
      isInfo: true,
      required: true,
    },
    {
      name: 'locationId',
      label: 'Location',
      type: 'lookup-company-section',
      model: Location,
      options: [
        { label: 'Unassigned', value: '' },
      ],
      default: '',
      isFormField: true,
      isInfo: true,
      required: true,
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textarea',
			default: '',
      isFormField: true,
      isInfo: true,
    },
    {
      name: 'board',
      label: 'Board',
      type: 'select',
      default: 'new-business',
      options: [
        { label: 'Personal', value: 'personal' },
        { label: 'Targets', value: 'targets' },
        { label: 'Sales Pipeline', value: 'new-business' },
        { label: 'Finance', value: 'finance' },
        { label: 'Customer Service', value: 'customer-service' },
      ],
      required: true,
      isInfo: true,
      infoSize: 'medium',
    },
    {
      name: 'status',
      label: 'Status',
			type: 'select',
			default: 'leads',
			options: [
        { label: 'Leads', value: 'leads', color: '#607D8B' },
        { label: 'Qualified', value: 'qualified', color: '#03A9F4' },
        { label: 'Proposal', value: 'proposal', color: '#E9A746' },
        { label: 'Under Review', value: 'under-review', color: '#E8AD55' },
        { label: 'On Hold', value: 'on-hold', color: '#DB584F' },
        { label: 'Closed (Won)', value: 'closed-won', color: '#4CAF50' },
        { label: 'Closed (Lost)', value: 'closed-lost', color: '#021532' },
        { label: 'Unqualified', value: 'unqualified', color: '#B00020' },
			],
      required: true,
      isSortable: true,
      isFormField: true,
      isInfo: true,
      infoSize: 'medium',
      formFieldSize: 'small',
      isTableColumn: true,
    },
    {
      name: 'category',
      label: 'Category',
      type: 'select',
      default: '',
      options: [
        { label: 'Uncategorized', value: '' },
        { label: 'Mill Direct', value: 'mill-direct' },
        { label: 'Recycling', value: 'recycling' },
        { label: 'Trash', value: 'trash' },
        { label: 'Grocery Retail', value: 'grocery-retail' },
				{ label: 'Non-grocery Retail', value: 'non-grocery-retail' },
				{ label: 'Printing', value: 'printing' },
        { label: 'Manufacturing', value: 'manufacturing' },
        { label: 'Converting', value: 'converting' },
      ],
      isFormField: true,
      isInfo: true,
      infoSize: 'medium',
      formFieldSize: 'small',
      isTableColumn: true,
    },
    {
      name: 'priority',
      label: 'Priority',
      type: 'select',
      default: 'low',
      options: [
        { label: 'Low', value: 'low', color: '#2FA0B5' },
        { label: 'Medium', value: 'medium', color: '#E8AD55' },
        { label: 'High', value: 'high', color: '#D04B52' },
      ],
      required: true,
      isSortable: true,
      isFormField: true,
      isInfo: true,
      infoSize: 'medium',
      formFieldSize: 'small',
      isTableColumn: true,
    },
		{
			name: 'dueDate',
			label: 'Due Date',
			type: 'date',
			default: '',
			isSortable: true,
      isFormField: true,
      isInfo: true,
			isTableColumn: true,
      formFieldSize: 'medium',
		},
		{
      name: 'isPushed',
      label: 'cieTrade',
      type: 'checkbox',
      default: false,
      isTableColumn: true,
      isInfo: true,
      infoSize: 'medium',
    },
		{
			name: 'assignedTo',
			label: 'Assigned To',
			type: 'lookup-input',
      model: User,
      required: true,
			default: '',
      isFormField: true,
      isInfo: true,
      infoSize: 'large',
      formFieldSize: 'medium',
			isTableColumn: true,
    },
		{
      name: 'serviceType',
      label: 'Type of Service',
      type: 'select',
      default: '',
      options: [
        { label: 'No Type', value: '' },
        { label: 'Contracted Business', value: 'contracted' },
        { label: 'Spot Business', value: 'spot' },
        { label: 'Recurring Non-Contracted', value: 'recurring-non-contracted' },
      ],
      isFormField: true,
      isInfo: true,
      infoSize: 'large',
      formFieldSize: 'large',
      isTableColumn: true,
    },
    {
      name: 'members',
      label: 'Members',
      type: 'lookup-multiple',
      model: User,
      default: [],
      formFieldSize: 'large',
    },
    {
      name: 'commentCount',
      label: 'Comments',
      type: 'number',
      default: 0,
      isSortable: true,
      isTableColumn: true,
      // icon: { icon: 'chat_bubble_outline' },
    },
		{
			name: 'createdBy',
			label: 'Created By',
			type: 'lookup-input',
      model: User,
			default: '',
      readOnly: true,
      isInfo: true,
		},
    {
      name: 'created',
      label: 'Created',
      type: 'date',
      default: '',
      readOnly: true,
      isSortable: true,
      isInfo: true,
    },
    {
      name: 'updated',
      label: 'Updated',
      type: 'date',
      default: '',
      readOnly: true,
      isSortable: true,
      isInfo: true,
    }
  ];

	static completedStatuses: string[] =  ['closed-won', 'closed-lost', 'unqualified'];

	static requiredPNLStatuses: string[] = ['proposal', 'under-review', 'closed-won'];

	static requiredPushStatuses: string[] = ['closed-won'];

	static warnings: Record<string, string> = {
		...Task.warnings,
		noPNL: 'No grades or expenses have been added to the P&L.',
		notPushed: 'This task\'s company and location data has not been pushed to cieTrade',
	};

	static hasPNLWarning(record: TaskSchema): boolean {
		if (! record.hasPNL && this.requiredPNLStatuses.includes(record.status) && ! record.archived) return true;
		return false;
	}

	static hasPushWarning(record: TaskSchema): boolean {
		if (! record.isPushed && this.requiredPushStatuses.includes(record.status) && ! record.archived) return true;
		return false;
	}

	static getWarnings<T>(record: T): string[] {
		const warnings: string[] = super.getWarnings<T>(record);
		if (this.hasPNLWarning(record as unknown as TaskSchema)) {
			warnings.push(this.warnings.noPNL);
		}
		if (this.hasPushWarning(record as unknown as TaskSchema)) {
			warnings.push(this.warnings.notPushed);
		}
		return warnings;
	}

	static async pushToCieTrade(token: string, company: CompanySchema, location: LocationSchema, cieTradeCounterparty?: CieTradeCounterpartySchema, cieTradeLocation?: CieTradeLocationSchema) {
		const response = await fetch(`${process.env.REACT_APP_API_URL}/cietrade/push`, {
      method: 'POST',
      body: JSON.stringify({
				crmCompany: company,
				crmLocation: location,
				counterpartyId: cieTradeCounterparty?.cieTradeID,
				cpAddressLabel: cieTradeLocation?.label,
			}),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    switch (response.status) {
      case 200: return await response.json();
      default: throw new APIError(response.status, 'Error pushing to cieTrade');
    }
	}
}

export default NewBusinessTask;
