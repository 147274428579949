import React from 'react';
import classNames from 'classnames';
import Report from '../../models/tables/Report';
import Dialog, { Props as DialogProps } from '../Dialog/Dialog';
import { ReportSchema } from '../../types';
import './ReportDetailDialog.scss';
import Info from '../Info/Info';
import InfoByField from '../InfoByField/InfoByField';
import IconMessage from '../IconMessage/IconMessage';
import DateSpan from '../DateSpan/DateSpan';
import Badge from '../Badge/Badge';

export interface Props extends Omit<DialogProps, 'children'> {
  record?: ReportSchema;
  disabled?: boolean;
}

export interface State {}

class ReportDetailDialog extends React.Component<Props, State> {

  static defaultProps = {
    ...Dialog.defaultProps,
  };

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { disabled, record, className, ...restProps } = this.props;
    const containerClass = classNames('fourg-report-detail-dialog', className);
    const modelOptions = Report.getOptions();
		const fields = Report.getFieldsBy('isInfo', true);
    return (
      <Dialog
      className={containerClass}
			size='small'
      {...restProps}>
				<div className='fourg-report-detail-dialog__scroller'>
					{record ? (
						<Info>
							{fields.map((field) => (
								<InfoByField<ReportSchema>
								size={field.infoSize}
								variant="quiet"
								key={`form-field-${field.name}`}
								field={field}
								value={record[field.name as keyof ReportSchema]}>
									{field.name === 'worksheetNumbers' ? (
										<div className="fourg-report-detail-dialog__worksheets">
											{record.worksheetNumbers.split(',').map((worksheet) => {
												const worksheetPieces = worksheet.split('|');
												const worksheetNumber = worksheetPieces[0];
												const worksheetDate = new Date(worksheetPieces[1]);
												return (
													<div key={worksheetNumber} className="fourg-report-detail-dialog__worksheet-info">
														<Badge>{worksheetNumber}</Badge>
														<DateSpan date={worksheetDate} hasTimeAgo={true} />
													</div>
												);
											})}
										</div>
									) : undefined}
								</InfoByField>
							))}
						</Info>
					) : (
						<IconMessage heading='No Report Selected' icon={{ icon: modelOptions.icon }}>
							{'Select a report to view its details.'}
						</IconMessage>
					)}
				</div>
      </Dialog>
    );
  }
}

export default ReportDetailDialog;
