import Table from '../Table';
import User from './User';
import { TableOptions, TableLabels, TableEndpoints, TableField, ReminderSchema } from '../../types';

class Reminder extends Table {

  static options: TableOptions = {
    name: 'To-Do',
    slug: 'reminders',
    labelKey: 'title',
    valueKey: 'id',
    lookupKey: 'reminders',
    icon: 'alarm',
    defaultOrder: 'reminder',
  };

  static labels: TableLabels = {
    description: 'All To-Dos in the 4G Recycling Site Manager.',
    pageTitle: 'To-Do Manager',
    singular: 'To-Do',
    plural: 'To-Dos',
    viewSingular: 'View To-Do',
    viewPlural: 'View To-Dos',
    selectSingular: 'Select To-Do',
    selectPlural: 'Select To-Dos',
    addSingular: 'Add To-Do',
    addPlural: 'Add To-Dos',
    editSingular: 'Edit To-Do',
    editPlural: 'Edit To-Dos',
    addedSingular: 'To-Do added',
    addedPlural: 'To-Dos added',
    updatedSingular: 'To-Do updated',
    updatedPlural: 'To-Dos updated',
    deletedSingular: 'To-Do deleted',
    deletedPlural: 'To-Dos deleted',
    archivedSingular: 'To-Do archived',
    archivedPlural: 'To-Dos archived',
    restoredSingular: 'To-Do restored',
    restoredPlural: 'To-Dos restored',
    errorFetchingSingular: 'Error fetching To-Do',
    errorFetchingPlural: 'Error fetching To-Dos',
    errorAddingSingular: 'Error adding To-Do',
    errorAddingPlural: 'Error adding To-Dos',
    errorUpdatingSingular: 'Error updating To-Do',
    errorUpdatingPlural: 'Error updating To-Dos',
    errorDeletingSingular: 'Error deleting To-Do',
    errorDeletingPlural: 'Error deleting To-Dos',
    errorArchivingSingular: 'Error archiving To-Do',
    errorArchivingPlural: 'Error archiving To-Dos',
    errorRestoringSingular: 'Error restoring To-Do',
    errorRestoringPlural: 'Error restoring To-Dos',
    notFoundSingular: 'To-Do not found',
    notFoundPlural: 'No To-Dos found',
    loadingSingular: 'Loading To-Do',
    loadingSingularEllipsis: 'Loading To-Do...',
    loadingPlural: 'Loading To-Dos',
    loadingPluralEllipsis: 'Loading To-Dos...',
    search: 'Search To-Dos',
    searchEllipsis: 'Search To-Dos...',
    filter: 'Filter To-Dos',
    settings: 'To-Dos Settings',
  };

  static endpoints: TableEndpoints = {
    readRecords: 'dashboard/reminders',
    readRecord: 'dashboard/reminder/:id',
    createRecord: 'dashboard/reminder',
    updateRecord: 'dashboard/reminder/:id',
    updateRecords: 'dashboard/reminders',
    patchRecord: 'dashboard/reminder/:id',
    deleteRecord: 'dashboard/reminder/:id',
    archiveRecord: 'dashboard/reminder/:id/archive',
    restoreRecord: 'dashboard/reminder/:id/restore',
  };

  static fields: TableField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'text',
      default: '',
      readOnly: true,
			isInfo: true,
    },
    {
      name: 'title',
      label: 'Subject',
      type: 'text',
      default: '',
			isInfo: true,
      isSortable: true,
      isFormField: true,
      isTableColumn: true,
      required: true,
    },
    {
      name: 'description',
      label: 'Notes',
      type: 'textarea',
      default: '',
      isFormField: true,
      isInfo: true,
    },
		{
      name: 'reminder',
      label: 'Reminder',
      type: 'recurrence',
      default: {
        interval: undefined,
        date: '',
        nextOccurrence: undefined,
      },
			isTableColumn: true,
			isFormField: true,
      formFieldSize: 'large',
      isSortable: true,
    },
    {
			name: 'assignedTo',
			label: 'Assigned To',
			type: 'lookup-input',
      model: User,
      required: true,
			default: '',
      isFormField: true,
      isInfo: true,
      formFieldSize: 'medium',
      infoSize: 'large',
			isTableColumn: true,
    },
    {
      name: 'completed',
      label: 'Completed',
      type: 'checkbox',
      default: false,
      isSortable: true,
      isTableColumn: true,
      isInfo: true,
      infoSize: 'medium',
      columnWidth: 120,
    },
    {
			name: 'resourceId',
			label: 'Resource',
			type: 'resource',
      isInfo: true,
      isTableColumn: true,
			default: '',
      readOnly: true,
		},
    {
			name: 'createdBy',
			label: 'Created By',
			type: 'lookup-input',
      model: User,
      isInfo: true,
      isTableColumn: false,
			default: '',
      readOnly: true,
		},
    {
      name: 'created',
      label: 'Created',
      type: 'date',
      default: '',
      readOnly: true,
      isSortable: true,
      isInfo: true,
    },
    {
      name: 'updated',
      label: 'Updated',
      type: 'date',
      default: '',
      readOnly: true,
      isSortable: true,
			isInfo: true,
    }
  ];

	static warnings: Record<string, string> = {
		pastDue: 'The due date is past due.',
	};

	static getWarnings<T>(record: T): string[] {
		const warnings: string[] = [];
		if (this.isPastDue(record as unknown as ReminderSchema)) {
			warnings.push(this.warnings.pastDue);
		}
		return warnings;
	}

  static isPastDue(record: ReminderSchema) {
    let isPastDue = false;
    const now = new Date();
    if (record.dueDate) {
      isPastDue = (now.getTime() > new Date(record.dueDate).getTime());
    } else if (record.reminder && record.reminder.date && ! record.reminder.interval) {
      isPastDue = (now.getTime() > new Date(record.reminder.date).getTime());
    }
    return isPastDue;
  }

}

export default Reminder;
