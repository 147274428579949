import React from 'react';
import classNames from 'classnames';
import Icon, { Props as IconProps } from '../Icon/Icon';
import Loader from '../Loader/Loader';
import Radio from '../Radio/Radio';
import IconMessage from '../IconMessage/IconMessage';
import ActionToggle from '../ActionToggle/ActionToggle';
import { TableColumn, UIOption } from '../../types';
import './Table.scss';

export interface Props<T extends Record<string, any> = Record<string, any>> {
  id?: string;
  className?: string;
  columns: TableColumn<T>[];
  records: T[];
  order?: string;
  disabled?: boolean;
  isLoading?: boolean;
  isScrollable?: boolean;
  valueKey: keyof T;
  rowRadioName?: string;
  notFoundIcon?: IconProps['src'];
  notFoundHeading: string;
  notFoundSubheading?: string;
  getRowActions?: (record: T) => UIOption[];
  getRowWarnings?: (record: T) => string[];
  onOrderChange?: (order: string, e: React.MouseEvent<HTMLButtonElement>) => void;
  onRowClick?: (record: T, e: React.MouseEvent<HTMLTableRowElement>) => void;
  // onRowRadioChange: (record: T, e: React.ChangeEvent<HTMLInputElement>) => void;
  onRowDoubleClick: (record: T, e: React.MouseEvent<HTMLTableRowElement>) => void;
  onRowActionsChange: (value: UIOption['value'], record: T, e: React.MouseEvent<HTMLButtonElement>) => void;
  renderCell: (value: any, column: TableColumn<T>, record: T) => React.ReactNode;
  isRowActive?: (record: T) => boolean;
  isRowAlternate?: (record: T) => boolean;
}

export interface State {

}

class Table<T extends Record<string, any> = Record<string, any>> extends React.Component<Props<T>, State> {

  private radios: React.RefObject<Radio>[] = [];

  static defaultProps = {
    valueKey: 'id',
    columns: [],
    records: [],
    isScrollable: false,
    notFoundIcon: { icon: 'list_alt' },
    // onRowClick: console.info,
    // onRowRadioChange: console.info,
    onRowDoubleClick: console.info,
    onRowActionsChange: console.info,
    renderCell: (value: any) => ( <span>{value}</span> ),
  };

  constructor(props: Props<T>) {
    super(props);
    this.handleOrderButtonClick = this.handleOrderButtonClick.bind(this);
  }

  handleOrderButtonClick(key: keyof T, e: React.MouseEvent<HTMLButtonElement>) {
    const { order, onOrderChange } = this.props;
    if (order && onOrderChange) {
      let newOrder = order;
      const isDesc = order.startsWith('-');
      const orderKey = this.getOrderKey();
      if (orderKey) {
        if (orderKey === key) {
          newOrder = isDesc ? orderKey : `-${orderKey}`;
        } else {
          newOrder = key.toString();
        }
      }
      onOrderChange(newOrder, e);
    }
  }

  getOrderKey() {
    const { order } = this.props;
    let orderKey = order;
    if (order && order.startsWith('-')) {
      orderKey = order.replace('-', '');
    }
    return orderKey;
  }

  handleRowClick(record: T, rowIndex: number, e: React.MouseEvent<HTMLTableRowElement>) {
    const { onRowClick } = this.props;
    const radio = this.radios[rowIndex] ? this.radios[rowIndex].current : undefined;
    radio?.focus();
    if (onRowClick) onRowClick(record, e);
  }

  render() {
    const { isRowAlternate, getRowWarnings, disabled, getRowActions, notFoundIcon, notFoundHeading, notFoundSubheading, rowRadioName, valueKey, isScrollable, columns, records, order,/*  onRowRadioChange, */ onRowActionsChange, isRowActive, onOrderChange, renderCell, onRowDoubleClick, onRowClick, isLoading, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-table', {
      'fourg-table--desc': (order && order.startsWith('-')),
      'fourg-table--scrollable': isScrollable,
      'fourg-table--loading': isLoading,
      'fourg-table--empty': (records.length <= 0),
      'fourg-table--disabled': disabled,
    }, className);
    return (
      <div className={containerClass}>
        {(! isLoading && (records.length <= 0)) && (
          <IconMessage
          className="fourg-table__not-found"
          icon={notFoundIcon}
          heading={notFoundHeading}
          subheading={notFoundSubheading} />
        )}
        {isLoading && (
          <Loader
          className="fourg-table__loader"
          position="absolute" />
        )}
        <div className="fourg-table__scroller">
          <table className="fourg-table__table" {...restProps}>
            <thead className="fourg-table__thead">
              <tr className="fourg-table__tr">
                {(columns.length > 0) && columns.map((column, columnIndex) => (
                  <th
                  key={`th-${columnIndex}`}
                  className="fourg-table__th"
                  title={column.key.toString()}
                  /* style={column.width ? { width: `${column.width}px` } : undefined} */>
                    <button
										type="button"
										disabled={! column.isSortable}
										className="fourg-table__order-button"
										onClick={e => this.handleOrderButtonClick(column.key, e)}>
											{column.icon ? (
												<Icon
												className="fourg-table__order-button-icon"
												src={column.icon}
												label={column.label} />
											) : (
												<span className="fourg-table__order-button-label">{column.label}</span>
											)}

											{(column.key === this.getOrderKey()) && (
												<Icon
												className="fourg-table__order-button-caret"
												src={{ icon: 'expand_less' }}
												label="Order" />
											)}
										</button>
                  </th>
                ))}
								{Boolean(getRowWarnings) && (
									<th className="fourg-table__th fourg-table__th--warnings">
										<button
										type="button"
										disabled={true}
										className="fourg-table__order-button">
											<Icon
											className="fourg-table__order-button-caret"
											src={{ icon: 'warning_amber' }}
											label={'Warnings'} />
										</button>
									</th>
								)}
                {Boolean(getRowActions) && (
                  <th className="fourg-table__th fourg-table__th--actions">
                    <button
                    type="button"
                    disabled={true}
                    className="fourg-table__order-button">
                      <Icon
                      className="fourg-table__order-button-caret"
                      src={{ icon: 'more_vert' }}
                      label={'Row Actions'} />
                    </button>
                  </th>
                )}
              </tr>
            </thead>
            {(!isLoading && (records.length > 0)) && (
              <tbody className="fourg-table__tbody">
                {records.map((record, rowIndex) => {
                  const rowValue = record[valueKey];
                  const isActive = isRowActive ? isRowActive(record) : false;
                  const isAlternate = isRowAlternate ? isRowAlternate(record) : false;
									const rowWarnings = getRowWarnings ? getRowWarnings(record) : [];
                  const rowActions = getRowActions ? getRowActions(record) : [];
                  const rowClass = classNames('fourg-table__tr', {
                    'fourg-table__tr--active': isActive,
                    'fourg-table__tr--alternate': isAlternate,
                    'fourg-table__tr--error': (rowWarnings.length > 0),
                  });
                  return (
                    <tr
                    key={`tr-${rowIndex}`}
                    className={rowClass}
                    onClick={e => this.handleRowClick(record, rowIndex, e)}
                    onDoubleClick={e => onRowDoubleClick(record, e)}>
                      {(columns.length > 0) && columns.map((column, columnIndex) => (
                        <td
                        key={`td-${columnIndex}`}
                        className="fourg-table__td"
                        /* style={column.width ? { width: `${column.width}px` } : undefined} */>
                          <div className="fourg-table__value">
                            {(rowRadioName && (columnIndex === 0)) && (
                              <Radio
                              disabled={disabled}
                              className="fourg-table__row-radio"
                              ref={radio => this.radios[rowIndex] = { current: radio }}
                              name={rowRadioName}
                              checked={isActive}
                              value={(typeof rowValue === 'string' || typeof rowValue === 'number') ? String(rowValue) : undefined}
                              onChange={() => {}} />
                            )}
                            {renderCell(record[column.key], column, record)}
                          </div>
                        </td>
                      ))}
											{Boolean(getRowWarnings) && (
												<td className="fourg-table__td fourg-table__td--warnings">
													{(rowWarnings.length > 0) && (
														<div className="fourg-table__row-warnings" title={rowWarnings.join('\n')}>
															<Icon
															className="fourg-table__row-warnings-icon"
															src={{ icon: 'warning_amber' }}
															label={'Warnings'} />
															<span>{rowWarnings.length.toLocaleString()}</span>
														</div>
													)}
												</td>
											)}
											{Boolean(getRowActions) && (
												<td className="fourg-table__td fourg-table__td--actions">
													{(rowActions.length > 0) && (
														<ActionToggle
														disabled={disabled}
														className="fourg-table__row-actions"
														variant="mini"
														icon={{ icon: 'more_vert' }}
														label={'Row Actions'}
														isIconOnly={true}
														anchor="top-right"
														options={rowActions}
														value=""
														onChange={(value, e) => onRowActionsChange(value, record, e)} />
													)}
												</td>
											)}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </div>
    );
  }
}

export default Table;
