import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { Route, RouteComponentProps } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Button/Button';
import Select from '../../components/Select/Select';
import ActionToggle from '../../components/ActionToggle/ActionToggle';
import IconMessageDialog from '../../components/IconMessageDialog/IconMessageDialog';
import Page from '../../components/Page/Page';
import ErrorPage from '../ErrorPage/ErrorPage';
import FormDialog from '../../components/FormDialog/FormDialog';
import ReminderFormDialog from '../../components/ReminderFormDialog/ReminderFormDialog';
import TaskFormDialog from '../../components/TaskFormDialog/TaskFormDialog';
import TaskSidebar from '../../components/TaskSidebar/TaskSidebar';
import LookupDialog from '../../components/LookupDialog/LookupDialog';
import ConvertToSubtaskDialog from '../../components/ConvertToSubtaskDialog/ConvertToSubtaskDialog';
import TaskComments from '../../components/TaskComments/TaskComments';
import CreateAttachmentsDialog from '../../components/CreateAttachmentsDialog/CreateAttachmentsDialog';
import ShareTaskDialog from '../../components/ShareTaskDialog/ShareTaskDialog';
import TaskRemoveSelfDialog from '../../components/TaskRemoveSelfDialog/TaskRemoveSelfDialog';
import CieTradeFormDialog from '../../components/CieTradeFormDialog/CieTradeFormDialog';
import Task from '../../models/tables/Task';
import NewBusinessTask from '../../models/tables/tasks/NewBusinessTask';
import FinanceTask from '../../models/tables/tasks/FinanceTask';
import CustomerServiceTask from '../../models/tables/tasks/CustomerServiceTask';
import PersonalTask from '../../models/tables/tasks/PersonalTask';
import TargetTask from '../../models/tables/tasks/TargetTask';
import Attachment from '../../models/tables/Attachment';
import Reminder from '../../models/tables/Reminder';
import Grade from '../../models/tables/Grade';
import Expense from '../../models/tables/Expense';
import { LookupContext } from '../../contexts';
import { APIError } from '../../errors';
import { TaskSchema, UserSchema, ActionSchema, AttachmentSchema, GradeSchema, ExpenseSchema, Lookup, Socket, SocketMessage, Auth, Breadcrumb, UIOption, ReminderSchema, CompanySchema, LocationSchema, CieTradeCounterpartySchema, CieTradeLocationSchema, ContactSchema } from '../../types';
import { mergeRecords } from '../../utils';
import './TaskPage.scss';

export interface RouteParams {
  id: string;
}

export interface Props extends RouteComponentProps<RouteParams> {
  id?: string;
  className?: string;
  auth: Auth;
  socket: Socket;
}

export interface State {
  record?: TaskSchema;
  actions: ActionSchema[];
  totalActions: number;
  readDate?: Date;
  lookup: Lookup;
  isLoading: boolean;
  isUpdating: boolean;
  isPaging: boolean;
  isMembersDialogOpen: boolean;
  isUploadDialogOpen: boolean;
  isUpdateDialogOpen: boolean;
  isArchiveDialogOpen: boolean;
  isCreateReminderDialogOpen: boolean;
  isConvertToSubtaskDialogOpen: boolean;
  isConvertToLeadDialogOpen: boolean;
  isShareDialogOpen: boolean;
  isRemoveSelfDialogOpen: boolean;
  isCreateGradeDialogOpen: boolean;
  isCreateExpenseDialogOpen: boolean;
	isCietradeDialogOpen: boolean;
  deletingAttachment?: AttachmentSchema,
  deletingReminder?: ReminderSchema,
  deletingGrade?: GradeSchema,
  deletingExpense?: ExpenseSchema,
  page: number;
  error?: APIError;
}

class TaskPage extends React.Component<Props, State> {

  private comments = React.createRef<TaskComments>();
  private createTaskDialog = React.createRef<TaskFormDialog>();
  private createReminderDialog = React.createRef<ReminderFormDialog>();
  private createGradeDialog = React.createRef<FormDialog<GradeSchema>>();
  private createExpenseDialog = React.createRef<FormDialog<ExpenseSchema>>();

  constructor(props: Props) {
    super(props);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleSidebarManageMembersClick = this.handleSidebarManageMembersClick.bind(this);
    this.handleSidebarAddSubtaskActionChange = this.handleSidebarAddSubtaskActionChange.bind(this);
    this.handleSidebarAttachFileClick = this.handleSidebarAttachFileClick.bind(this);
    this.handleCreateTaskDialogClose = this.handleCreateTaskDialogClose.bind(this);
    this.handleSidebarRemoveMemberClick = this.handleSidebarRemoveMemberClick.bind(this);
    this.handleMembersDialogClose = this.handleMembersDialogClose.bind(this);
    this.handleMembersDialogSubmit = this.handleMembersDialogSubmit.bind(this);
    this.handleCommentSubmit = this.handleCommentSubmit.bind(this);
    this.handleCommentEditSubmit = this.handleCommentEditSubmit.bind(this);
    this.handleCommentDelete = this.handleCommentDelete.bind(this);
    this.handleCommentsLoadMore = this.handleCommentsLoadMore.bind(this);
    this.handleUpdateDialogSubmit = this.handleUpdateDialogSubmit.bind(this);
    this.handleUpdateDialogClose = this.handleUpdateDialogClose.bind(this);
    this.handleCreateAttachmentsDialogSubmit = this.handleCreateAttachmentsDialogSubmit.bind(this);
    this.handleCreateAttachmentsDialogClose = this.handleCreateAttachmentsDialogClose.bind(this);
    this.handleUpdateAttachmentDialogSubmit = this.handleUpdateAttachmentDialogSubmit.bind(this);
    this.handleUpdateGradeDialogSubmit = this.handleUpdateGradeDialogSubmit.bind(this);
    this.handleUpdateExpenseDialogSubmit = this.handleUpdateExpenseDialogSubmit.bind(this);
    this.handleUpdateAttachmentDialogClose = this.handleUpdateAttachmentDialogClose.bind(this);
    this.handlePageActionsChange = this.handlePageActionsChange.bind(this);
    this.handleAnnouncementBarActionClick = this.handleAnnouncementBarActionClick.bind(this);
    this.handleSocketMessage = this.handleSocketMessage.bind(this);
    this.handleAttachmentCardActionsChange = this.handleAttachmentCardActionsChange.bind(this);
    this.handleCreateTaskDialogSubmit = this.handleCreateTaskDialogSubmit.bind(this);
    this.handleCreateTaskDialogSecondary = this.handleCreateTaskDialogSecondary.bind(this);
    this.handleSubtaskCardClick = this.handleSubtaskCardClick.bind(this);
    this.handleUpdateClick = this.handleUpdateClick.bind(this);
    this.handleArchiveDialogRestore = this.handleArchiveDialogRestore.bind(this);
    this.handleArchiveDialogClose = this.handleArchiveDialogClose.bind(this);
    this.handleSidebarAddReminderClick = this.handleSidebarAddReminderClick.bind(this);
    this.handleCreateReminderDialogSubmit = this.handleCreateReminderDialogSubmit.bind(this);
    this.handleCreateReminderDialogSecondary = this.handleCreateReminderDialogSecondary.bind(this);
    this.handleCreateReminderDialogClose = this.handleCreateReminderDialogClose.bind(this);
    this.handleReminderCardActionsChange = this.handleReminderCardActionsChange.bind(this);
    this.handleGradeCardActionsChange = this.handleGradeCardActionsChange.bind(this);
    this.handleExpenseCardActionsChange = this.handleExpenseCardActionsChange.bind(this);
    this.handleReminderCardCompletedChange = this.handleReminderCardCompletedChange.bind(this);
    this.handleUpdateReminderDialogSubmit = this.handleUpdateReminderDialogSubmit.bind(this);
    this.handleUpdateReminderDialogClose = this.handleUpdateReminderDialogClose.bind(this);
    this.handleConvertToSubtaskDialogClose = this.handleConvertToSubtaskDialogClose.bind(this);
    this.handleConvertToSubtaskDialogSubmit = this.handleConvertToSubtaskDialogSubmit.bind(this);
    this.handleConvertToLeadDialogClose = this.handleConvertToLeadDialogClose.bind(this);
    this.handleConvertToLeadDialogSubmit = this.handleConvertToLeadDialogSubmit.bind(this);
    this.handleShareClick = this.handleShareClick.bind(this);
    this.handleShareDialogClose = this.handleShareDialogClose.bind(this);
    this.handleRemoveSelfDialogSubmit = this.handleRemoveSelfDialogSubmit.bind(this);
    this.handleRemoveSelfDialogClose = this.handleRemoveSelfDialogClose.bind(this);
    this.handleAttachmentDeleteConfirmDialogDelete = this.handleAttachmentDeleteConfirmDialogDelete.bind(this);
    this.handleAttachmentDeleteConfirmDialogClose = this.handleAttachmentDeleteConfirmDialogClose.bind(this);
    this.handleReminderDeleteConfirmDialogDelete = this.handleReminderDeleteConfirmDialogDelete.bind(this);
    this.handleGradeDeleteConfirmDialogDelete = this.handleGradeDeleteConfirmDialogDelete.bind(this);
    this.handleExpenseDeleteConfirmDialogDelete = this.handleExpenseDeleteConfirmDialogDelete.bind(this);
    this.handleReminderDeleteConfirmDialogClose = this.handleReminderDeleteConfirmDialogClose.bind(this);
    this.handleGradeDeleteConfirmDialogClose = this.handleGradeDeleteConfirmDialogClose.bind(this);
    this.handleExpenseDeleteConfirmDialogClose = this.handleExpenseDeleteConfirmDialogClose.bind(this);
    this.handleCreateGradeClick = this.handleCreateGradeClick.bind(this);
    this.handleCreateGradeDialogSubmit = this.handleCreateGradeDialogSubmit.bind(this);
    this.handleCreateGradeDialogClose = this.handleCreateGradeDialogClose.bind(this);
    this.handleUpdateGradeDialogClose = this.handleUpdateGradeDialogClose.bind(this);
    this.handleCreateExpenseClick = this.handleCreateExpenseClick.bind(this);
    this.handleCreateExpenseDialogSubmit = this.handleCreateExpenseDialogSubmit.bind(this);
    this.handleCreateExpenseDialogClose = this.handleCreateExpenseDialogClose.bind(this);
    this.handleUpdateExpenseDialogClose = this.handleUpdateExpenseDialogClose.bind(this);
		this.handleCieTradeDialogSubmit = this.handleCieTradeDialogSubmit.bind(this);
		this.handleCieTradeDialogClose = this.handleCieTradeDialogClose.bind(this);
    this.state = {
      isLoading: true,
      isUpdating: false,
      isPaging: false,
      isMembersDialogOpen: false,
      isUploadDialogOpen: false,
      isUpdateDialogOpen: false,
      isArchiveDialogOpen: false,
      isCreateReminderDialogOpen: false,
      isConvertToSubtaskDialogOpen: false,
      isConvertToLeadDialogOpen: false,
      isShareDialogOpen: false,
      isRemoveSelfDialogOpen: false,
      isCreateGradeDialogOpen: false,
      isCreateExpenseDialogOpen: false,
      isCietradeDialogOpen: false,
      totalActions: 0,
      actions: [],
      lookup: {},
      page: 1,
    };
  }

  componentDidMount() {
    this.readRecord();
  }

  componentDidUpdate(prevProps: Props) {
    const { match } = this.props;
    if (prevProps.match.params.id !== match.params.id) {
      this.socketDisconnect(parseInt(prevProps.match.params.id, 10));
      this.readRecord();
    }
  }

  componentWillUnmount() {
    const { match } = this.props;
    this.socketDisconnect(parseInt(match.params.id, 10));
  }

  socketConnect(id: TaskSchema['id']) {
    const { socket } = this.props;
		if (socket.current?.readyState === WebSocket.OPEN) {
			socket.current?.send(JSON.stringify({ action: `task/${id}:connect` }));
		}
		window.addEventListener('fourg:socket-message', this.handleSocketMessage);
  }

  socketDisconnect(id: TaskSchema['id']) {
    const { socket } = this.props;
		if (socket.current?.readyState === WebSocket.OPEN) {
			socket.current?.send(JSON.stringify({ action: `task/${id}:disconnect` }));
		}
		window.removeEventListener('fourg:socket-message', this.handleSocketMessage);
  }

  handleSocketMessage(e: CustomEventInit<SocketMessage>) {
		const { auth } = this.props;
    const { record, lookup } = this.state;
    if (record && e.detail && (e.detail.type === 'task') && (e.detail.stream === `task/${record.id}`)) {
      const socketMessage = { ...e.detail } as SocketMessage<ActionSchema>;
      const { message, meta, data } = socketMessage;
      const model = this.getModelByBoard(record.board);
			const newUsers = mergeRecords<UserSchema>(meta?.users || [], auth.user ? [auth.user] : []);
      this.setState({
        record: model.getSyncedRecord(record, socketMessage),
        totalActions: this.getSyncedActionsTotal(message, data),
        actions: this.getSyncedActions(message, data),
        lookup: {
          ...lookup,
					users: mergeRecords<UserSchema>(newUsers, lookup.users),
					companies: mergeRecords<CompanySchema>(meta?.companies, lookup.companies),
					locations: mergeRecords<LocationSchema>(meta?.locations, lookup.locations),
					tasks: mergeRecords<TaskSchema>(meta?.tasks, lookup.tasks),
					attachments: mergeRecords<AttachmentSchema>(meta?.attachments, lookup.attachments),
					reminders: mergeRecords<ReminderSchema>(meta?.reminders, lookup.reminders),
					grades: mergeRecords<GradeSchema>(meta?.grades, lookup.grades),
					expenses: mergeRecords<ExpenseSchema>(meta?.expenses, lookup.expenses),
					contacts: mergeRecords<ContactSchema>(meta?.contacts, lookup.contacts),
        },
      });
      const comments = this.comments.current;
      if (comments) {
        if (message === 'comment') {
          comments.scrollToStart();
        }
        if (message === 'update-comment') {
          comments.setCommentEditorValue(data, false);
        }
      }
    }
  }

  getModelByBoard(board: string = 'peronal'): typeof Task {
    switch (board) {
      case 'targets': return TargetTask;
      case 'new-business': return NewBusinessTask;
      case 'finance': return FinanceTask;
      case 'customer-service': return CustomerServiceTask;
      default: return PersonalTask;
    }
  }

  getSyncedActions(message: SocketMessage['message'], action: ActionSchema) {
    const { actions } = this.state;
    switch (message) {
      case 'update-subtask': return actions;
      case 'update-reminder': return actions;
      case 'update-attachment': return actions;
      // case 'update-grade': return actions;
      // case 'update-expense': return actions;
      case 'update-comment': return actions;
      case 'delete-comment': return this.removeAction(action);
      default:
        switch (action.action) {
          case 'update-new-business-title': return actions;
          default: return this.prependAction(action);
        }
    }
  }

  getSyncedActionsTotal(message: SocketMessage['message'], action: ActionSchema) {
    const { totalActions } = this.state;
    switch (message) {
      case 'update-subtask': return totalActions;
      case 'update-reminder': return totalActions;
      case 'update-attachment': return totalActions;
      // case 'update-grade': return totalActions;
      // case 'update-expense': return totalActions;
      case 'update-comment': return totalActions;
      case 'delete-comment': return (totalActions > 0) ? (totalActions - 1) : 0;
      default:
        switch (action.action) {
          case 'update-new-business-title': return totalActions;
          default: return (totalActions + 1);
        }
    }
  }

  getAttachment(location: RouteComponentProps<RouteParams>['location']) {
    const params = new URLSearchParams(location.search);
    return params.get('attachment') || undefined;
  }

  getReminder(location: RouteComponentProps<RouteParams>['location']) {
    const params = new URLSearchParams(location.search);
    return params.get('reminder') || undefined;
  }

	getGrade(location: RouteComponentProps<RouteParams>['location']) {
    const params = new URLSearchParams(location.search);
    return params.get('grade') || undefined;
  }

	getExpense(location: RouteComponentProps<RouteParams>['location']) {
    const params = new URLSearchParams(location.search);
    return params.get('expense') || undefined;
  }

  async readRecord(isInitialLoad: boolean = true) {
    const { auth, socket, match } = this.props;
    this.setState({
      isLoading: isInitialLoad,
      error: undefined,
    });
    try {
      const token = await auth.getToken();
      const readDate = new Date();
      const { meta, data } = await Task.readRecord<TaskSchema>(token, match.params.id, {
        created: `-${readDate.toISOString()}`,
      });
			const newUsers = mergeRecords<UserSchema>(meta?.users || [], auth.user ? [auth.user] : []);
      this.setState({
        record: data,
        totalActions: meta.total || 0,
        actions: data.actions?.data || [],
        readDate: readDate,
        lookup: {
          users: newUsers,
          companies: meta.companies,
          locations: meta.locations,
          tasks: meta.tasks,
          attachments: meta.attachments,
          reminders: meta.reminders,
					grades: meta.grades,
					expenses: meta.expenses,
					contacts: meta.contacts,
        },
        isArchiveDialogOpen: Boolean(data.archived),
        isLoading: false,
        page: 1,
      });
      if (isInitialLoad && (socket.current?.readyState === WebSocket.OPEN)) {
        this.socketConnect(parseInt(match.params.id, 10));
      }
      const comments = this.comments.current;
      comments?.scrollToStart();
    } catch(error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({
        record: undefined,
        totalActions: 0,
        actions: [],
        lookup: {},
        isLoading: false,
        page: 1,
        error: error as Error,
      });
      this.socketDisconnect(parseInt(match.params.id, 10));
    }
  }

  async patchRecord(updates: Partial<TaskSchema>) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.patchRecord<TaskSchema>(token, record.id.toString(), updates);
        toast.success(model.getLabel('updatedSingular'));
        this.setState({ isUpdating: false });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  handleStatusChange(value: string) {
    this.patchRecord({ status: value });
  }

  handleSidebarManageMembersClick() {
    this.setState({ isMembersDialogOpen: true });
  }

  handleSidebarRemoveMemberClick(record: UserSchema) {
    this.removeMembers([record.id]);
  }

  handleSidebarAddSubtaskActionChange(value: UIOption['value']) {
    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    params.set('create', value);
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleMembersDialogClose() {
    this.setState({ isMembersDialogOpen: false });
  }

  async addMembers(userIDs: string[]) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.addMembers(token, record.id, userIDs);
        toast.success(model.getLabel('updatedSingular'));
        this.setState({
          isUpdating: false,
          isMembersDialogOpen: false,
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  async removeMembers(userIDs: string[]) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.removeMembers(token, record.id, userIDs);
        toast.success(model.getLabel('updatedSingular'));
        this.setState({
          isUpdating: false,
          isMembersDialogOpen: false,
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isUpdating: false,
        });
      }
    }
  }

  handleMembersDialogSubmit(value: string[], added: string[], removed: string[]) {
    // TODO: come up with a way to ensure only one toast shows, and await add/remove to
    // simulate a single response instead of 2. This single response should also only update
    // the state a single time.
    if (added.length > 0) this.addMembers(added);
    if (removed.length > 0) this.removeMembers(removed);
  }

  handleCommentSubmit(value: string) {
    if (value) {
      this.createComment(value);
    }
  }

  async createComment(value: string) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.createComment(token, record.id, value);
        toast.success(model.getLabel('updatedSingular'));
        this.setState({ isUpdating: false });
        const comments = this.comments.current;
        if (comments) {
          comments.setEmptyComment();
        }
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isUpdating: false,
        });
      }
    }
  }

  async updateComment(action: ActionSchema) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.updateComment(token, record.id, action);
        toast.success(model.getLabel('updatedSingular'));
        this.setState({ isUpdating: false });
        const comments = this.comments.current;
        comments?.finishEdit();
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isUpdating: false,
        });
      }
    }
  }

  async deleteComment(action: ActionSchema) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.deleteComment(token, record.id, action);
        toast.success(model.getLabel('updatedSingular'));
        this.setState({ isUpdating: false });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isUpdating: false,
        });
      }
    }
  }

  async createReminder(reminder: ReminderSchema, isCreateReminderDialogOpen: boolean = false) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.createReminder(token, record.id, reminder);
        toast.success(Reminder.getLabel('addedSingular'));
        this.setState({
          isUpdating: false,
          isCreateReminderDialogOpen: isCreateReminderDialogOpen,
        });
        const createReminderDialog = this.createReminderDialog.current;
        createReminderDialog?.setDefaultRecord();
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  async updateReminder(reminder: ReminderSchema) {
    const { auth, history, location } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.updateReminder(token, record.id, reminder);
        toast.success(Reminder.getLabel('updatedSingular'));
        this.setState({
          isUpdating: false,
        });
        const params = new URLSearchParams(location.search);
        params.delete('reminder');
        history.push(`${location.pathname}?${params.toString()}`);
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  async deleteReminder(reminder: ReminderSchema) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.deleteReminder(token, record.id, reminder);
        toast.success(Reminder.getLabel('deletedSingular'));
        this.setState({
          isUpdating: false,
          deletingReminder: undefined,
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isUpdating: false,
        });
      }
    }
  }

  async markReminderAsComplete(reminder: ReminderSchema) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.markReminderAsComplete(token, record.id, reminder);
        toast.success(Reminder.getLabel('updatedSingular'));
        this.setState({ isUpdating: false });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  async markReminderAsIncomplete(reminder: ReminderSchema) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.markReminderAsIncomplete(token, record.id, reminder);
        toast.success(Reminder.getLabel('updatedSingular'));
        this.setState({ isUpdating: false });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  prependAction(newAction: ActionSchema) {
    const { actions } = this.state;
    let newActions = [...actions];
    if (actions.find(action => (action.id === newAction.id))) {
      newActions = newActions.map(action => (action.id === newAction.id) ? newAction : action);
    } else {
      newActions = [newAction, ...newActions];
    }
    return newActions;
  }

  removeAction(removedAction: ActionSchema) {
    const { actions } = this.state;
    return actions.filter(action => (action.id !== removedAction.id));
  }

  async readActions() {
    const { auth } = this.props;
    const { record, page, readDate, lookup, actions } = this.state;
    if (record) {
      this.setState({ isPaging: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        const { meta, data } = await model.readRecord<TaskSchema>(token, record.id.toString(), {
          page: (page + 1),
          created: readDate ? `-${readDate.toISOString()}` : undefined,
        });
				const newUsers = mergeRecords<UserSchema>(meta?.users || [], auth.user ? [auth.user] : []);
        this.setState({
          record: data,
          totalActions: meta.total || 0,
          actions: mergeRecords<ActionSchema>(data.actions?.data, actions),
          lookup: {
            ...lookup,
						users: mergeRecords<UserSchema>(newUsers, lookup.users),
						companies: mergeRecords<CompanySchema>(meta.companies, lookup.companies),
						locations: mergeRecords<LocationSchema>(meta.locations, lookup.locations),
						tasks: mergeRecords<TaskSchema>(meta.tasks, lookup.tasks),
						attachments: mergeRecords<AttachmentSchema>(meta.attachments, lookup.attachments),
						reminders: mergeRecords<ReminderSchema>(meta.reminders, lookup.reminders),
						grades: mergeRecords<GradeSchema>(meta.grades, lookup.grades),
						expenses: mergeRecords<ExpenseSchema>(meta.expenses, lookup.expenses),
						contacts: mergeRecords<ContactSchema>(meta.contacts, lookup.contacts),
          },
          isPaging: false,
          page: (page + 1),
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isPaging: false });
      }
    }
  }

  handleCommentsLoadMore() {
    this.readActions();
  }

  handleSidebarAttachFileClick() {
    this.setState({ isUploadDialogOpen: true });
  }

  handleCreateAttachmentsDialogClose() {
    this.setState({ isUploadDialogOpen: false });
  }

  handleCreateGradeDialogClose() {
    this.setState({ isCreateGradeDialogOpen: false });
  }

  handleCreateExpenseDialogClose() {
    this.setState({ isCreateExpenseDialogOpen: false });
  }

  handleCreateTaskDialogClose() {
    const { history, location } = this.props;
    const params = new URLSearchParams(location.search);
    params.delete('create');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleUpdateAttachmentDialogSubmit(attachment: AttachmentSchema) {
    this.updateAttachment(attachment);
  }

	handleUpdateGradeDialogSubmit(grade: GradeSchema) {
    this.updateGrade(grade);
  }

	handleUpdateExpenseDialogSubmit(expense: ExpenseSchema) {
    this.updateExpense(expense);
  }

  handleCreateGradeDialogSubmit(grade: GradeSchema) {
    this.createGrade(grade);
  }

  handleCreateExpenseDialogSubmit(expense: ExpenseSchema) {
    this.createExpense(expense);
  }

  handleUpdateAttachmentDialogClose() {
    const { history, location } = this.props;
    const params = new URLSearchParams(location.search);
    params.delete('attachment');
    history.push(`${location.pathname}?${params.toString()}`);
  }

	handleUpdateGradeDialogClose() {
    const { history, location } = this.props;
    const params = new URLSearchParams(location.search);
    params.delete('grade');
    history.push(`${location.pathname}?${params.toString()}`);
  }

	handleUpdateExpenseDialogClose() {
    const { history, location } = this.props;
    const params = new URLSearchParams(location.search);
    params.delete('expense');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleCreateAttachmentsDialogSubmit(records: AttachmentSchema[]) {
    this.createAttachments(records);
  }

  async createAttachments(attachments: AttachmentSchema[], isUploadDialogOpen: boolean = false) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.createAttachments(token, record.id, attachments);
        toast.success(Attachment.getLabel('addedPlural'));
        this.setState({
          isUpdating: false,
          isUploadDialogOpen: isUploadDialogOpen,
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isUpdating: false,
        });
      }
    }
  }

  async updateAttachment(attachment: AttachmentSchema) {
    const { auth, history, location } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.updateAttachment(token, record.id, attachment);
        toast.success(Attachment.getLabel('updatedSingular'));
        this.setState({ isUpdating: false }, () => {
					const params = new URLSearchParams(location.search);
					params.delete('attachment');
					history.push(`${location.pathname}?${params.toString()}`);
				});
      } catch(error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

	async updateGrade(grade: GradeSchema) {
    const { auth, history, location } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.updateGrade(token, record.id, grade);
        toast.success(Grade.getLabel('updatedSingular'));
        this.setState({ isUpdating: false }, () => {
					const params = new URLSearchParams(location.search);
					params.delete('grade');
					history.push(`${location.pathname}?${params.toString()}`);
				});
      } catch(error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

	async updateExpense(expense: ExpenseSchema) {
    const { auth, history, location } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.updateExpense(token, record.id, expense);
        toast.success(Expense.getLabel('updatedSingular'));
        this.setState({ isUpdating: false }, () => {
					const params = new URLSearchParams(location.search);
					params.delete('expense');
					history.push(`${location.pathname}?${params.toString()}`);
				});
      } catch(error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  async deleteAttachment(attachment: AttachmentSchema) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.deleteAttachment(token, record.id, attachment.id);
        toast.success(Attachment.getLabel('deletedSingular'));
        this.setState({
          isUpdating: false,
          deletingAttachment: undefined,
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isUpdating: false,
        });
      }
    }
  }

	async deleteGrade(grade: GradeSchema) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.deleteGrade(token, record.id, grade.id);
        toast.success(Grade.getLabel('deletedSingular'));
        this.setState({
          isUpdating: false,
          deletingGrade: undefined,
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isUpdating: false,
        });
      }
    }
  }

	async deleteExpense(expense: ExpenseSchema) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.deleteExpense(token, record.id, expense.id);
        toast.success(Expense.getLabel('deletedSingular'));
        this.setState({
          isUpdating: false,
          deletingExpense: undefined,
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isUpdating: false,
        });
      }
    }
  }

  async createSubtask(subtask: TaskSchema, isCreateTaskDialogOpen: boolean = false) {
    const { auth, location, history } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.createRecord(token, { ...subtask, parentId: record.id });
        toast.success(model.getLabel('addedSingular'));
        const createTaskDialog = this.createTaskDialog.current;
        createTaskDialog?.setDefaultRecord();
        this.setState({
          isUpdating: false,
        });
        if (! isCreateTaskDialogOpen) {
          const params = new URLSearchParams(location.search);
          params.delete('create');
          history.push(`${location.pathname}?${params.toString()}`);
        }
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isUpdating: false,
        });
      }
    }
  }

  async createGrade(grade: GradeSchema, isCreateGradeDialogOpen: boolean = false) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.createGrade(token, record.id, grade);
        toast.success(Grade.getLabel('addedSingular'));
        const createGradeDialog = this.createGradeDialog.current;
        createGradeDialog?.setDefaultRecord();
        this.setState({
          isUpdating: false,
          isCreateGradeDialogOpen: isCreateGradeDialogOpen,
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isUpdating: false,
        });
      }
    }
  }

  async createExpense(expense: ExpenseSchema, isCreateExpenseDialogOpen: boolean = false) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.createExpense(token, record.id, expense);
        toast.success(Expense.getLabel('addedSingular'));
        const createExpenseDialog = this.createExpenseDialog.current;
        createExpenseDialog?.setDefaultRecord();
        this.setState({
          isUpdating: false,
          isCreateExpenseDialogOpen: isCreateExpenseDialogOpen,
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isUpdating: false,
        });
      }
    }
  }

  async archiveRecord() {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.archiveRecord(token, record.id.toString());
        toast.success(model.getLabel('archivedSingular'));
        this.setState({ isUpdating: false });
      } catch(error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  async restoreRecord() {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.restoreRecord(token, record.id.toString());
        toast.success(model.getLabel('restoredSingular'));
        this.setState({
          isArchiveDialogOpen: false,
          isUpdating: false,
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  handlePageActionsChange(value: string) {
    switch (value) {
      case 'archive': return this.archiveRecord();
      case 'restore': return this.restoreRecord();
      case 'convert-to-subtask': return this.setState({ isConvertToSubtaskDialogOpen: true });
      case 'convert-to-lead': return this.setState({ isConvertToLeadDialogOpen: true });
      case 'remove-self': return this.setState({ isRemoveSelfDialogOpen: true });
			case 'push-to-cietrade': return this.setState({ isCietradeDialogOpen: true });
			case 'print': return this.navigateToPrintable();
    }
  }

  handleAnnouncementBarActionClick(value: UIOption['value']) {
    switch (value) {
      case 'restore': return this.restoreRecord();
    }
  }

	navigateToPrintable() {
		const { history, match } = this.props;
		history.push(`/tasks/${match.params.id}/print`);
	}

  getCreate(location: RouteComponentProps<RouteParams>['location']) {
    const params = new URLSearchParams(location.search);
    return params.get('create') || undefined;
  }

  async followRecord() {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.followRecord(token, record.id);
        const newRecord = { ...record, following: true };
        toast.success(model.getLabel('updatedSingular'));
        this.setState({
          record: newRecord,
          isUpdating: false,
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  async unfollowRecord() {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.unfollowRecord(token, record.id);
        toast.success(model.getLabel('updatedSingular'));
        const newRecord = { ...record, following: false };
        this.setState({
          record: newRecord,
          isUpdating: false,
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  async convertToSubtask(parentID: TaskSchema['id']) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.convertToSubtask(token, record.id, parentID);
        toast.success(model.getLabel('updatedSingular'));
        this.setState({
          isUpdating: false,
          isConvertToSubtaskDialogOpen: false,
        });

      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  async convertToLead(newRecord: TaskSchema) {
    const { auth, history } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const { data } = await NewBusinessTask.createRecord(token, newRecord);
        TargetTask.patchRecord(token, record.id.toString(), {
          companyId: newRecord.companyId,
          locationId: newRecord.locationId,
        });
        await TargetTask.convertToSubtask(token, record.id, data.id);
        await TargetTask.archiveRecord(token, record.id.toString());
        toast.success('Target converted to Lead');
        this.setState({
          isUpdating: false,
          isConvertToLeadDialogOpen: false,
        });
        history.push(NewBusinessTask.getRecordLink(data));
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

	async pushToCieTrade(company: CompanySchema, location: LocationSchema, cieTradeCounterparty?: CieTradeCounterpartySchema, cieTradeLocation?: CieTradeLocationSchema) {
    const { auth } = this.props;
		this.setState({ isUpdating: true });
		try {
			const token = await auth.getToken();
			await NewBusinessTask.pushToCieTrade(token, company, location, cieTradeCounterparty, cieTradeLocation);
			toast.success('Successfully pushed to cieTrade');
			this.setState({
				isUpdating: false,
				isCietradeDialogOpen: false,
			});
		} catch (error) {
			console.error(error);
			toast.error((error as Error).message);
			this.setState({ isUpdating: false });
		}
  }

  async removeSelfFromTask(assignedTo: TaskSchema['assignedTo']) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.removeSelf(token, record.id, assignedTo);
        toast.success(model.getLabel('updatedSingular'));
        this.setState({
          isUpdating: false,
          isRemoveSelfDialogOpen: false,
        });
      } catch(error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({ isUpdating: false });
      }
    }
  }

  handleFollowChange(isFollowing: boolean) {
    if (isFollowing) {
      this.followRecord();
    } else {
      this.unfollowRecord();
    }
  }

  handleCommentEditSubmit(record: ActionSchema) {
    this.updateComment(record);
  }

  handleCommentDelete(record: ActionSchema) {
    this.deleteComment(record);
  }

  handleCreateTaskDialogSubmit(record: TaskSchema) {
    this.createSubtask(record);
  }

  handleCreateTaskDialogSecondary(record: TaskSchema) {
    this.createSubtask(record, true);
  }

  handleAttachmentCardActionsChange(value: string, record: AttachmentSchema) {
    const { location, history } = this.props;
    switch (value) {
      case 'edit':
        const params = new URLSearchParams(location.search);
        params.set('attachment', record.id.toString());
        history.push(`${location.pathname}?${params.toString()}`);
        break;
      case 'delete':
        this.setState({ deletingAttachment: record });
        break;
    }
  }

  handleSubtaskCardClick(subtask: TaskSchema) {
    const { history } = this.props;
    history.push(`/tasks/${subtask.id}`);
  }

  getBreadcrumbs() {
    const { record, lookup } = this.state;
    let breadcrumbs: Breadcrumb[] = [];
    if (record) {
      const model = this.getModelByBoard(record.board);
      const boardLabel = model.getFieldOptionLabel<TaskSchema>('board', record.board) || record.board;
      const boardLink = `/tasks?board=${record.board}`;
      if (record.parentId) {
        const parent = lookup.tasks?.find(task => (task.id === record.parentId));
        const parentLabel = parent ? model.getRecordLabel<TaskSchema>(parent) : `${model.getLabel('singular')} ${record.parentId}`;
        breadcrumbs = [
          { label: boardLabel, to: boardLink },
          { label: parentLabel, to: `/tasks/${record.parentId}` },
          { label: model.getRecordLabel<TaskSchema>(record) },
        ];
      } else {
        breadcrumbs = [
          { label: boardLabel, to: boardLink },
          { label: model.getRecordLabel<TaskSchema>(record) },
        ];
      }
    }
    return breadcrumbs;
  }

  handleUpdateClick() {
    this.setState({ isUpdateDialogOpen: true });
  }

  handleUpdateDialogSubmit(record: TaskSchema) {
    this.updateRecord(record);
  }

  handleUpdateDialogClose() {
    this.setState({ isUpdateDialogOpen: false });
  }

  async updateRecord(record: TaskSchema) {
    const { auth } = this.props;
    this.setState({ isUpdating: true });
    try {
      const token = await auth.getToken();
      const model = this.getModelByBoard(record.board);
      await model.updateRecord<TaskSchema>(token, record.id.toString(), record);
      toast.success(model.getLabel('updatedSingular'));
      this.setState({
        isUpdating: false,
        isUpdateDialogOpen: false,
      });
    } catch(error) {
      console.error(error);
      toast.error((error as Error).message);
      this.setState({ isUpdating: false });
    }
  }

  handleArchiveDialogRestore() {
    this.restoreRecord();
  }

  handleArchiveDialogClose() {
    this.setState({ isArchiveDialogOpen: false });
  }

  handleSidebarAddReminderClick() {
    this.setState({ isCreateReminderDialogOpen: true });
  }

  handleCreateReminderDialogSubmit(record: ReminderSchema) {
    this.createReminder(record);
  }

  handleCreateReminderDialogSecondary(record: ReminderSchema) {
    this.createReminder(record, true);
  }

  handleCreateReminderDialogClose() {
    this.setState({ isCreateReminderDialogOpen: false });
  }

  handleUpdateReminderDialogSubmit(record: ReminderSchema) {
    this.updateReminder(record);
  }

  handleUpdateReminderDialogClose() {
    const { history, location } = this.props;
    const params = new URLSearchParams(location.search);
    params.delete('reminder');
    history.push(`${location.pathname}?${params.toString()}`);
  }

  handleReminderCardCompletedChange(value: boolean, record: ReminderSchema) {
    if (value) {
      this.markReminderAsComplete(record);
    } else {
      this.markReminderAsIncomplete(record);
    }
  }

  handleReminderCardActionsChange(value: UIOption['value'], record: ReminderSchema) {
    const { location, history } = this.props;
    switch (value) {
      case 'edit':
        const params = new URLSearchParams(location.search);
        params.set('reminder', record.id.toString());
        history.push(`${location.pathname}?${params.toString()}`);
        break;
      case 'delete':
        this.setState({ deletingReminder: record });
        break;
    }
  }

	handleGradeCardActionsChange(value: UIOption['value'], record: GradeSchema) {
    const { location, history } = this.props;
    switch (value) {
      case 'edit':
        const params = new URLSearchParams(location.search);
        params.set('grade', record.id.toString());
        history.push(`${location.pathname}?${params.toString()}`);
        break;
      case 'delete':
        this.setState({ deletingGrade: record });
        break;
    }
  }

	handleExpenseCardActionsChange(value: UIOption['value'], record: ExpenseSchema) {
    const { location, history } = this.props;
    switch (value) {
      case 'edit':
        const params = new URLSearchParams(location.search);
        params.set('expense', record.id.toString());
        history.push(`${location.pathname}?${params.toString()}`);
        break;
      case 'delete':
        this.setState({ deletingExpense: record });
        break;
    }
  }

  handleCreateGradeClick() {
    this.setState({ isCreateGradeDialogOpen: true });
  }

  handleCreateExpenseClick() {
    this.setState({ isCreateExpenseDialogOpen: true });
  }

  handleConvertToSubtaskDialogClose() {
    this.setState({ isConvertToSubtaskDialogOpen: false });
  }

	handleCieTradeDialogClose() {
    this.setState({ isCietradeDialogOpen: false });
  }

	handleCieTradeDialogSubmit(company: CompanySchema, location: LocationSchema, cieTradeCounterparty?: CieTradeCounterpartySchema, cieTradeLocation?: CieTradeLocationSchema) {
		this.pushToCieTrade(company, location, cieTradeCounterparty, cieTradeLocation);
	}

  handleConvertToSubtaskDialogSubmit(parentID: TaskSchema['id']) {
    this.convertToSubtask(parentID);
  }

  handleConvertToLeadDialogClose() {
    this.setState({ isConvertToLeadDialogOpen: false });
  }

  handleConvertToLeadDialogSubmit(newRecord: TaskSchema) {
    this.convertToLead(newRecord);
  }

  handleShareClick() {
    this.setState({ isShareDialogOpen: true });
  }

  handleShareDialogClose() {
    this.setState({ isShareDialogOpen: false });
  }

  getMoreActions() {
    const { auth } = this.props;
    const { record } = this.state;
    let actions: UIOption[] = [];
    if (record) {
      actions = [
        {
          value: record.archived ? 'restore' : 'archive',
          label: record.archived ? 'Restore' : 'Archive',
        },
      ];
      if ((record.board === 'targets') && ! record.parentId) {
        actions.push({
          value: 'convert-to-lead',
          label: 'Convert to Lead',
        });
      }
      if (! ['new-business', 'personal'].includes(record.board) && ((record.subtaskIds?.length || 0) < 1) && ! record.parentId) {
        actions.push({
          value: 'convert-to-subtask',
          label: 'Make subtask of...',
        });
      }
      if (auth.user && ((auth.user.id === record.assignedTo) || record.members?.includes(auth.user.id))) {
        actions.push({
          value: 'remove-self',
          label: 'Remove Me',
        });
      }
			if ((record.board === 'new-business') && ['closed-won'].includes(record.status) && ! record.isPushed) {
        actions.push({
          value: 'push-to-cietrade',
          label: 'Push to cieTrade',
        });
      }
			if ((record.board === 'new-business') && ['closed-won'].includes(record.status)) {
        actions.push({
          value: 'print',
          label: 'Print',
        });
      }
    }
    return actions;
  }

  handleRemoveSelfDialogSubmit(assignedTo: TaskSchema['assignedTo']) {
    this.removeSelfFromTask(assignedTo);
  }

  handleRemoveSelfDialogClose() {
    this.setState({ isRemoveSelfDialogOpen: false });
  }

  handleAttachmentDeleteConfirmDialogDelete() {
    const { deletingAttachment } = this.state;
    if (deletingAttachment) {
      this.deleteAttachment(deletingAttachment);
    }
  }

  handleAttachmentDeleteConfirmDialogClose() {
    this.setState({ deletingAttachment: undefined });
  }

  handleReminderDeleteConfirmDialogDelete() {
    const { deletingReminder } = this.state;
    if (deletingReminder) {
      this.deleteReminder(deletingReminder);
    }
  }

	handleGradeDeleteConfirmDialogDelete() {
    const { deletingGrade } = this.state;
    if (deletingGrade) {
      this.deleteGrade(deletingGrade);
    }
  }

	handleExpenseDeleteConfirmDialogDelete() {
    const { deletingExpense } = this.state;
    if (deletingExpense) {
      this.deleteExpense(deletingExpense);
    }
  }

  handleReminderDeleteConfirmDialogClose() {
    this.setState({ deletingReminder: undefined });
  }

	handleGradeDeleteConfirmDialogClose() {
    this.setState({ deletingGrade: undefined });
  }

	handleExpenseDeleteConfirmDialogClose() {
    this.setState({ deletingExpense: undefined });
  }

  render() {
    const { auth, socket, className, match, location, history, staticContext, ...restProps } = this.props;
    const { record, isLoading, isUpdating, isPaging, isShareDialogOpen, isCietradeDialogOpen, isCreateReminderDialogOpen, isRemoveSelfDialogOpen, isConvertToLeadDialogOpen, isUpdateDialogOpen, isMembersDialogOpen, isUploadDialogOpen, isConvertToSubtaskDialogOpen, isArchiveDialogOpen, isCreateGradeDialogOpen, isCreateExpenseDialogOpen, deletingAttachment, deletingReminder, deletingGrade, deletingExpense, actions, totalActions, lookup, error } = this.state;
    const containerClass = classNames('fourg-task-page', className);
    const model = this.getModelByBoard(record?.board);
    const createParam = this.getCreate(location);
    const statusField = model.getField<TaskSchema>('status');
    const membersField = model.getField<TaskSchema>('members');
    const reminderID = this.getReminder(location);
    const attachmentID = this.getAttachment(location);
    const gradeID = this.getGrade(location);
    const expenseID = this.getExpense(location);
    const taskLabel = record ? model.getRecordLabel<TaskSchema>(record) : `${model.getLabel('singular')} ${match.params.id}`;
    const newBusinessEnforcedValues: Partial<TaskSchema> = (record?.board === 'new-business') ? { companyId: record?.companyId, locationId: record?.locationId } : {};
    return isLoading ? (
      <Loader position="absolute" />
    ) : ! record ? (
      <Route render={(routeProps) => (
        <ErrorPage status={error?.status} message={error?.message} {...routeProps} />
      )} />
    ) : (
      <LookupContext.Provider value={lookup}>
        <Page
        disabled={isUpdating}
        className={containerClass}
        breadcrumbs={this.getBreadcrumbs()}
        title={taskLabel}
        lastActive={new Date(record.updated)}
        announcementBarTitle={record.archived ? 'This task is archived, restore it to make changes.' : undefined}
        announcementBarActions={[
          { label: 'Restore', value: 'restore' },
        ]}
        onAnnouncementBarActionClick={this.handleAnnouncementBarActionClick}
        headerDescription={(
          <span className="fourg-task-page__header-description">
            <label
            className="fourg-task-page__header-status"
            htmlFor="task-page-header-select">
              <span>{'in status'}&nbsp;</span>
              <Select
              id="task-page-header-select"
              disabled={(isUpdating || record.archived)}
              variant="link"
              label={'Status'}
              value={record.status}
              options={statusField?.options}
              onChange={this.handleStatusChange} />
            </label>
          </span>
        )}
        actions={(
          <React.Fragment>
            <Button
            isIconOnly={true}
            variant="raised"
            disabled={(isUpdating || record.archived)}
            icon={{ icon: record.following ? 'bookmark' : 'bookmark_border' }}
            onClick={e => this.handleFollowChange(! record.following)}>
              {'Following'}
            </Button>
            <Button
            isIconOnly={true}
            variant="raised"
            disabled={(isUpdating || record.archived)}
            icon={{ icon: 'edit' }}
            onClick={this.handleUpdateClick}>
              {model.getLabel('editSingular')}
            </Button>
            <Button
            isIconOnly={true}
            variant="raised"
            disabled={isUpdating}
            icon={{ icon: 'share' }}
            onClick={this.handleShareClick}>
              {'Share'}
            </Button>
            <ActionToggle
            isIconOnly={true}
            variant={'raised'}
            label={'More Actions'}
            disabled={isUpdating}
            icon={{ icon: 'more_vert' }}
            onChange={this.handlePageActionsChange}
            anchor="top-right"
            options={this.getMoreActions()}
            value="" />
          </React.Fragment>
        )}
        {...restProps }>
          <TaskSidebar
          record={record}
          disabled={isUpdating}
          onRemoveMemberClick={this.handleSidebarRemoveMemberClick}
          onManageMembersClick={this.handleSidebarManageMembersClick}
          onAttachFileClick={this.handleSidebarAttachFileClick}
          onAttachmentCardActionsChange={this.handleAttachmentCardActionsChange}
          onAddSubtaskActionChange={this.handleSidebarAddSubtaskActionChange}
          onSubtaskCardClick={this.handleSubtaskCardClick}
          onAddReminderClick={this.handleSidebarAddReminderClick}
          onReminderCardCompletedChange={this.handleReminderCardCompletedChange}
          onReminderCardActionsChange={this.handleReminderCardActionsChange}
          onGradeCardActionsChange={this.handleGradeCardActionsChange}
          onExpenseCardActionsChange={this.handleExpenseCardActionsChange}
          onAddGradeClick={this.handleCreateGradeClick}
          onAddExpenseClick={this.handleCreateExpenseClick} />
          <TaskComments
          task={record}
          ref={this.comments}
          total={totalActions}
          records={actions}
          disabled={(isUpdating || record.archived)}
          isLoading={isPaging}
          onLoadMore={this.handleCommentsLoadMore}
          onCommentSubmit={this.handleCommentSubmit}
          onCommentEditSubmit={this.handleCommentEditSubmit}
          onCommentDelete={this.handleCommentDelete}
          onSubtaskCardClick={this.handleSubtaskCardClick} />
          <TaskFormDialog
          model={FinanceTask}
          disabled={(isUpdating || record.archived)}
          auth={auth}
          ref={this.createTaskDialog}
          title={FinanceTask.getLabel('addSingular')}
          isOpen={createParam === 'finance'}
          submitLabel={'Save'}
          secondaryLabel={'Save and Add Another'}
          cancelLabel={'Cancel'}
          onFormSubmit={this.handleCreateTaskDialogSubmit}
          onFormSecondary={this.handleCreateTaskDialogSecondary}
          onCloseClick={this.handleCreateTaskDialogClose}
          // onBackdropClick={this.handleCreateTaskDialogClose}
          onFormCancel={this.handleCreateTaskDialogClose}
          onEscape={this.handleCreateTaskDialogClose}
          initialValues={{
            assignedTo: auth.user?.id,
          }} />
          <TaskFormDialog
          model={CustomerServiceTask}
          disabled={(isUpdating || record.archived)}
          auth={auth}
          ref={this.createTaskDialog}
          title={CustomerServiceTask.getLabel('addSingular')}
          isOpen={(createParam === 'customer-service')}
          submitLabel={'Save'}
          secondaryLabel={'Save and Add Another'}
          cancelLabel={'Cancel'}
          onFormSubmit={this.handleCreateTaskDialogSubmit}
          onFormSecondary={this.handleCreateTaskDialogSecondary}
          onCloseClick={this.handleCreateTaskDialogClose}
          // onBackdropClick={this.handleCreateTaskDialogClose}
          onFormCancel={this.handleCreateTaskDialogClose}
          onEscape={this.handleCreateTaskDialogClose}
          initialValues={{
            assignedTo: auth.user?.id,
          }} />
          <CreateAttachmentsDialog
          auth={auth}
          title={Attachment.getLabel('addPlural')}
          isOpen={isUploadDialogOpen}
          disabled={(isUpdating || record.archived)}
          multiple={true}
          // onBackdropClick={this.handleCreateAttachmentsDialogClose}
          onCloseClick={this.handleCreateAttachmentsDialogClose}
          onEscape={this.handleCreateAttachmentsDialogClose}
          onSubmit={this.handleCreateAttachmentsDialogSubmit} />
          <FormDialog<AttachmentSchema>
          auth={auth}
          title={Attachment.getLabel('editSingular')}
          model={Attachment}
          isOpen={Boolean(attachmentID)}
          recordID={attachmentID}
          submitLabel={'Update'}
          disabled={(isUpdating || record.archived)}
          cancelLabel={'Cancel'}
          onFormSubmit={this.handleUpdateAttachmentDialogSubmit}
          onCloseClick={this.handleUpdateAttachmentDialogClose}
          // onBackdropClick={this.handleUpdateAttachmentDialogClose}
          onFormCancel={this.handleUpdateAttachmentDialogClose}
          onEscape={this.handleUpdateAttachmentDialogClose} />
          <FormDialog<GradeSchema>
          ref={this.createGradeDialog}
          auth={auth}
          title={Grade.getLabel('addSingular')}
          model={Grade}
          isOpen={isCreateGradeDialogOpen}
          submitLabel={'Save'}
          disabled={(isUpdating || record.archived)}
          cancelLabel={'Cancel'}
          onFormSubmit={this.handleCreateGradeDialogSubmit}
          onCloseClick={this.handleCreateGradeDialogClose}
          // onBackdropClick={this.handleCreateGradeDialogClose}
          onFormCancel={this.handleCreateGradeDialogClose}
          onEscape={this.handleCreateGradeDialogClose} />
					<FormDialog<GradeSchema>
          auth={auth}
          title={Grade.getLabel('editSingular')}
          model={Grade}
          isOpen={Boolean(gradeID)}
          recordID={gradeID}
          submitLabel={'Update'}
          disabled={(isUpdating || record.archived)}
          cancelLabel={'Cancel'}
          onFormSubmit={this.handleUpdateGradeDialogSubmit}
          onCloseClick={this.handleUpdateGradeDialogClose}
          // onBackdropClick={this.handleUpdateGradeDialogClose}
          onFormCancel={this.handleUpdateGradeDialogClose}
          onEscape={this.handleUpdateGradeDialogClose} />
          <FormDialog<ExpenseSchema>
          ref={this.createExpenseDialog}
          auth={auth}
          title={Expense.getLabel('addSingular')}
          model={Expense}
          isOpen={isCreateExpenseDialogOpen}
          submitLabel={'Save'}
          disabled={(isUpdating || record.archived)}
          cancelLabel={'Cancel'}
          onFormSubmit={this.handleCreateExpenseDialogSubmit}
          onCloseClick={this.handleCreateExpenseDialogClose}
          // onBackdropClick={this.handleCreateExpenseDialogClose}
          onFormCancel={this.handleCreateExpenseDialogClose}
          onEscape={this.handleCreateExpenseDialogClose} />
					<FormDialog<ExpenseSchema>
          auth={auth}
          title={Expense.getLabel('editSingular')}
          model={Expense}
          isOpen={Boolean(expenseID)}
          recordID={expenseID}
          submitLabel={'Update'}
          disabled={(isUpdating || record.archived)}
          cancelLabel={'Cancel'}
          onFormSubmit={this.handleUpdateExpenseDialogSubmit}
          onCloseClick={this.handleUpdateExpenseDialogClose}
          // onBackdropClick={this.handleUpdateExpenseDialogClose}
          onFormCancel={this.handleUpdateExpenseDialogClose}
          onEscape={this.handleUpdateExpenseDialogClose} />
          <ReminderFormDialog
          resourceModel={Task}
          resourceID={record.id}
          ref={this.createReminderDialog}
          auth={auth}
          title={Reminder.getLabel('addSingular')}
          isOpen={isCreateReminderDialogOpen}
          submitLabel={'Save'}
          secondaryLabel={'Save and Add Another'}
          disabled={(isUpdating || record.archived)}
          cancelLabel={'Cancel'}
          onFormSubmit={this.handleCreateReminderDialogSubmit}
          onFormSecondary={this.handleCreateReminderDialogSecondary}
          onCloseClick={this.handleCreateReminderDialogClose}
          // onBackdropClick={this.handleCreateReminderDialogClose}
          onFormCancel={this.handleCreateReminderDialogClose}
          onEscape={this.handleCreateReminderDialogClose}
          initialValues={{
            assignedTo: auth.user?.id,
          }}
          enforcedValues={(record.board === 'personal') ? {
            assignedTo: auth.user?.id,
          } : undefined} />
          <ReminderFormDialog
          resourceModel={Task}
          resourceID={record.id}
          auth={auth}
          title={Reminder.getLabel('editSingular')}
          recordID={parseInt(reminderID || '', 10)}
          isOpen={Boolean(reminderID)}
          submitLabel={'Save'}
          disabled={(isUpdating || record.archived)}
          cancelLabel={'Cancel'}
          onFormSubmit={this.handleUpdateReminderDialogSubmit}
          onCloseClick={this.handleUpdateReminderDialogClose}
          // onBackdropClick={this.handleUpdateReminderDialogClose}
          onFormCancel={this.handleUpdateReminderDialogClose}
          onEscape={this.handleUpdateReminderDialogClose} />
          {(membersField && membersField.model) && (
            <LookupDialog<UserSchema>
            model={membersField.model}
            title={'Manage Members'}
            value={record.members}
            isOpen={isMembersDialogOpen}
            disabled={(isUpdating || record.archived)}
            onFormSubmit={this.handleMembersDialogSubmit}
            onFormCancel={this.handleMembersDialogClose}
            onBackdropClick={this.handleMembersDialogClose}
            onCloseClick={this.handleMembersDialogClose}
            onEscape={this.handleMembersDialogClose} />
          )}
          <TaskFormDialog
          model={model}
          recordID={record.id.toString()}
          disabled={(isUpdating || record.archived)}
          auth={auth}
          title={model.getLabel('editSingular')}
          isOpen={isUpdateDialogOpen}
          submitLabel={'Save'}
          cancelLabel={'Cancel'}
          enforcedValues={{
            board: record.board,
            ...newBusinessEnforcedValues,
          }}
          onFormSubmit={this.handleUpdateDialogSubmit}
          onCloseClick={this.handleUpdateDialogClose}
          // onBackdropClick={this.handleUpdateDialogClose}
          onFormCancel={this.handleUpdateDialogClose}
          onEscape={this.handleUpdateDialogClose} />
          {(record.board === 'targets') && (
            <TaskFormDialog
            model={NewBusinessTask}
            disabled={(isUpdating || record.archived)}
            auth={auth}
            enforcedValues={{
              board: 'new-business',
              status: 'leads',
            }}
            initialValues={{
              assignedTo: record.assignedTo,
              priority: record.priority,
              category: record.category,
              dueDate: record.dueDate,
              description: record.description,
            }}
            title={'Convert to Lead'}
            isOpen={isConvertToLeadDialogOpen}
            submitLabel={'Save'}
            cancelLabel={'Cancel'}
            onFormSubmit={this.handleConvertToLeadDialogSubmit}
            onCloseClick={this.handleConvertToLeadDialogClose}
            // onBackdropClick={this.handleConvertToLeadDialogClose}
            onFormCancel={this.handleConvertToLeadDialogClose}
            onEscape={this.handleConvertToLeadDialogClose} />
          )}
          <IconMessageDialog
          disabled={(isUpdating || ! record.archived)}
          icon={{ icon: 'archive' }}
          isOpen={isArchiveDialogOpen}
          title={model.getLabel('archivedSingular')}
          heading={`"${taskLabel}" is Archived`}
          subheading={`Restore this task to make changes, or close this message to browse its details and activity history.`}
          cancelLabel={'Close'}
          submitLabel={'Restore'}
          secondaryLabel={'Browse'}
          onSubmit={this.handleArchiveDialogRestore}
          onSecondary={this.handleArchiveDialogClose}
          onCancel={this.handleArchiveDialogClose}
          onCloseClick={this.handleArchiveDialogClose}
          onEscape={this.handleArchiveDialogClose}
          onBackdropClick={this.handleArchiveDialogClose} />
          <ConvertToSubtaskDialog
          recordID={record.id}
          auth={auth}
          isOpen={isConvertToSubtaskDialogOpen}
          disabled={(isUpdating || record.archived)}
          initialBoard={(record.board === 'targets') ? 'new-business' : record.board}
          disabledBoards={(record.board === 'targets') ? ['customer-service', 'finance'] : undefined}
          onFormCancel={this.handleConvertToSubtaskDialogClose}
          onCloseClick={this.handleConvertToSubtaskDialogClose}
          onEscape={this.handleConvertToSubtaskDialogClose}
          onBackdropClick={this.handleConvertToSubtaskDialogClose}
          onFormSubmit={this.handleConvertToSubtaskDialogSubmit} />
          <TaskRemoveSelfDialog
          disabled={(isUpdating || record.archived)}
          auth={auth}
          lookup={lookup}
          recordID={record.id}
          isOpen={isRemoveSelfDialogOpen}
          isAssignedToSelf={(record.assignedTo === auth.user?.id)}
          onFormSubmit={this.handleRemoveSelfDialogSubmit}
          onCloseClick={this.handleRemoveSelfDialogClose}
          onBackdropClick={this.handleRemoveSelfDialogClose}
          onFormCancel={this.handleRemoveSelfDialogClose}
          onEscape={this.handleRemoveSelfDialogClose} />
					<CieTradeFormDialog
          disabled={(isUpdating || record.archived)}
          auth={auth}
          title={'Push to cieTrade'}
          companyID={record.companyId?.toString()}
					locationID={record.locationId?.toString()}
          isOpen={isCietradeDialogOpen}
          submitLabel={'Save'}
          cancelLabel={'Cancel'}
          onFormSubmit={this.handleCieTradeDialogSubmit}
          onCloseClick={this.handleCieTradeDialogClose}
          // onBackdropClick={this.handleCieTradeDialogClose}
          onFormCancel={this.handleCieTradeDialogClose}
          onEscape={this.handleCieTradeDialogClose} />
          <ShareTaskDialog
          isOpen={isShareDialogOpen}
          recordID={record.id}
          onCloseClick={this.handleShareDialogClose}
          onEscape={this.handleShareDialogClose}
          onBackdropClick={this.handleShareDialogClose} />
          <IconMessageDialog
          className="fourg-task-page__delete-confirm-dialog"
          disabled={(isUpdating || record.archived)}
          title={`Delete ${Attachment.getLabel('singular')}`}
          isOpen={Boolean(deletingAttachment)}
          heading={`Are you sure you want to delete "${deletingAttachment ? Attachment.getRecordLabel<AttachmentSchema>(deletingAttachment) : 'this record'}?"`}
          deleteLabel={'Delete'}
          cancelLabel={'Cancel'}
          onDelete={this.handleAttachmentDeleteConfirmDialogDelete}
          onEscape={this.handleAttachmentDeleteConfirmDialogClose}
          onCloseClick={this.handleAttachmentDeleteConfirmDialogClose}
          onBackdropClick={this.handleAttachmentDeleteConfirmDialogClose}
          onCancel={this.handleAttachmentDeleteConfirmDialogClose} />
          <IconMessageDialog
          className="fourg-task-page__delete-confirm-dialog"
          disabled={(isUpdating || record.archived)}
          title={`Delete ${Reminder.getLabel('singular')}`}
          isOpen={Boolean(deletingReminder)}
          heading={`Are you sure you want to delete "${deletingReminder ? Reminder.getRecordLabel<ReminderSchema>(deletingReminder) : 'this record'}?"`}
          deleteLabel={'Delete'}
          cancelLabel={'Cancel'}
          onDelete={this.handleReminderDeleteConfirmDialogDelete}
          onEscape={this.handleReminderDeleteConfirmDialogClose}
          onCloseClick={this.handleReminderDeleteConfirmDialogClose}
          onBackdropClick={this.handleReminderDeleteConfirmDialogClose}
          onCancel={this.handleReminderDeleteConfirmDialogClose} />
					<IconMessageDialog
          className="fourg-task-page__delete-confirm-dialog"
          disabled={(isUpdating || record.archived)}
          title={`Delete ${Grade.getLabel('singular')}`}
          isOpen={Boolean(deletingGrade)}
          heading={`Are you sure you want to delete "${deletingGrade ? Grade.getRecordLabel(deletingGrade) : 'this record'}?"`}
          deleteLabel={'Delete'}
          cancelLabel={'Cancel'}
          onDelete={this.handleGradeDeleteConfirmDialogDelete}
          onEscape={this.handleGradeDeleteConfirmDialogClose}
          onCloseClick={this.handleGradeDeleteConfirmDialogClose}
          onBackdropClick={this.handleGradeDeleteConfirmDialogClose}
          onCancel={this.handleGradeDeleteConfirmDialogClose} />
					<IconMessageDialog
          className="fourg-task-page__delete-confirm-dialog"
          disabled={(isUpdating || record.archived)}
          title={`Delete ${Expense.getLabel('singular')}`}
          isOpen={Boolean(deletingExpense)}
          heading={`Are you sure you want to delete "${deletingExpense ? Expense.getRecordLabel(deletingExpense) : 'this record'}?"`}
          deleteLabel={'Delete'}
          cancelLabel={'Cancel'}
          onDelete={this.handleExpenseDeleteConfirmDialogDelete}
          onEscape={this.handleExpenseDeleteConfirmDialogClose}
          onCloseClick={this.handleExpenseDeleteConfirmDialogClose}
          onBackdropClick={this.handleExpenseDeleteConfirmDialogClose}
          onCancel={this.handleExpenseDeleteConfirmDialogClose} />
        </Page>
      </LookupContext.Provider>
    );
  }
}

export default TaskPage;
