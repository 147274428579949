import React from 'react';
import classNames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import './Menu.scss';

export interface Props extends RouteComponentProps {
  id?: string;
  className?: string;
}

export interface State {

}

class Menu extends React.Component<Props, State> {

  render() {
    const { className, children, match, location, history, staticContext, ...restProps } = this.props;
    const containerClass = classNames('fourg-menu', className);
    return (
      <nav className={containerClass} {...restProps}>
        <ul className="fourg-menu__menu">
          {children}
        </ul>
      </nav>
    );
  }
}

export default withRouter(Menu);
