import Table from '../Table';
import { TableOptions, TableLabels, TableField, TableEndpoints, GradeSchema, GradeTotals } from '../../types';
import User from './User';

class Grade extends Table {

  static options: TableOptions = {
    name: 'Grade',
    slug: 'grades',
    labelKey: 'name',
    valueKey: 'id',
    icon: 'recycling',
    defaultOrder: 'created',
  };

  static labels: TableLabels = {
    description: 'All grades in the 4G Recycling Site Manager.',
    pageTitle: 'Grade Manager',
    singular: 'Grade',
    plural: 'Grades',
    viewSingular: 'View Grade',
    viewPlural: 'View Grades',
    selectSingular: 'Select Grade',
    selectPlural: 'Select Grades',
    addSingular: 'Add Grade',
    addPlural: 'Add Grades',
    editSingular: 'Edit Grade',
    editPlural: 'Edit Grades',
    addedSingular: 'Grade added',
    addedPlural: 'Grades added',
    updatedSingular: 'Grade updated',
    updatedPlural: 'Grades updated',
    deletedSingular: 'Grade deleted',
    deletedPlural: 'Grades deleted',
    archivedSingular: 'Grade archived',
    archivedPlural: 'Grades archived',
    restoredSingular: 'Grade restored',
    restoredPlural: 'Grades restored',
    errorFetchingSingular: 'Error fetching Grade',
    errorFetchingPlural: 'Error fetching Grades',
    errorAddingSingular: 'Error adding Grade',
    errorAddingPlural: 'Error adding Grades',
    errorUpdatingSingular: 'Error updating Grade',
    errorUpdatingPlural: 'Error updating Grades',
    errorDeletingSingular: 'Error deleting Grade',
    errorDeletingPlural: 'Error deleting Grades',
    errorArchivingSingular: 'Error archiving Grade',
    errorArchivingPlural: 'Error archiving Grades',
    errorRestoringSingular: 'Error restoring Grade',
    errorRestoringPlural: 'Error restoring Grades',
    notFoundSingular: 'Grade not found',
    notFoundPlural: 'No Grades found',
    loadingSingular: 'Loading Grade',
    loadingSingularEllipsis: 'Loading Grade...',
    loadingPlural: 'Loading Grades',
    loadingPluralEllipsis: 'Loading Grades...',
    search: 'Search Grades',
    searchEllipsis: 'Search Grades...',
    filter: 'Filter Grades',
    settings: 'Grades Settings',
  };

  static endpoints: TableEndpoints = {
    readRecords: 'grades',
    readRecord: 'grade/:id',
    createRecord: 'grade',
    updateRecord: 'grade/:id',
    updateRecords: 'grades',
    patchRecord: 'grade/:id',
    deleteRecord: 'grade/:id',
    archiveRecord: 'grade/:id/archive',
    restoreRecord: 'grade/:id/restore',
  };

  static fields: TableField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'text',
      default: '',
      readOnly: true,
    },
    {
      name: 'name',
      label: 'Name',
      type: 'select-input',
      default: 'Aluminum Litho',
      required: true,
      isSortable: true,
			isTableColumn: true,
      isFormField: true,
      formFieldSize: 'medium',
			options: [
				{ value: 'Aluminum Litho', label: 'Aluminum Litho' },
				{ value: 'Beater Dye', label: 'Beater Dye' },
				{ value: 'Boxboard', label: 'Boxboard' },
				{ value: 'Coated Book', label: 'Coated Book' },
				{ value: 'Coated Groundwood', label: 'Coated Groundwood' },
				{ value: 'Coated Pub', label: 'Coated Pub' },
				{ value: 'Cores', label: 'Cores' },
				{ value: 'DLK', label: 'DLK' },
				{ value: 'EPS', label: 'EPS' },
				{ value: 'Hard White', label: 'Hard White' },
				{ value: 'HPDE', label: 'HPDE' },
				{ value: 'Heavy Printed SBS', label: 'Heavy Printed SBS' },
				{ value: 'Hogged Books', label: 'Hogged Books' },
				{ value: 'IGS', label: 'IGS' },
				{ value: 'LDPE', label: 'LDPE' },
				{ value: 'Light Printed SBS', label: 'Light Printed SBS' },
				{ value: 'Metal', label: 'Metal' },
				{ value: 'Mixed Paper', label: 'Mixed Paper' },
				{ value: 'News', label: 'News' },
				{ value: 'OCC', label: 'OCC' },
				{ value: 'PET', label: 'PET' },
				{ value: 'Plastic Window Envelope', label: 'Plastic Window Envelope' },
				{ value: 'Poly Board', label: 'Poly Board' },
				{ value: 'Soft White', label: 'Soft White' },
				{ value: 'Sorted Office Waste', label: 'Sorted Office Waste' },
				{ value: 'Sorted White Ledger', label: 'Sorted White Ledger' },
				{ value: 'Tissue', label: 'Tissue' },
				{ value: 'TMP', label: 'TMP' },
				{ value: 'Trash Disposal', label: 'Trash Disposal' },
				{ value: 'Tyvek', label: 'Tyvek' },
				{ value: 'UBC', label: 'UBC' },
				{ value: 'Unprinted SBS', label: 'Unprinted SBS' },
				{ value: 'White Blank News', label: 'White Blank News' },
				{ value: 'White Ledger', label: 'White Ledger' },
				{ value: 'Wrappers', label: 'Wrappers' },
			],
    },
		{
      name: 'type',
      label: 'Form Type',
      type: 'select',
      default: 'Bales',
      required: true,
      isSortable: true,
			isTableColumn: true,
      isFormField: true,
      formFieldSize: 'medium',
			options: [
				{ value: 'Bales', label: 'Bales' },
				{ value: 'Baskets', label: 'Baskets' },
				{ value: 'Butt Rolls', label: 'Butt Rolls' },
				{ value: 'Compacted', label: 'Compacted' },
				{ value: 'Loose', label: 'Loose' },
				{ value: 'Rolls', label: 'Rolls' },
				{ value: 'Skids', label: 'Skids' },
			],
    },
		{
      name: 'estWeight',
      label: 'Estimated Weight',
      type: 'number',
      min: 0,
      step: 0.01,
      default: undefined,
      isSortable: true,
			isTableColumn: true,
      isFormField: true,
      required: true,
      formFieldSize: 'medium',
    },
		{
      name: 'unit',
      label: 'Unit',
      type: 'select',
      default: 'tons',
      required: true,
      isSortable: true,
			isTableColumn: true,
      isFormField: true,
      formFieldSize: 'medium',
			options: [
				{ value: 'tons', label: 'tons' },
				{ value: 'lbs', label: 'lbs' },
			],
    },
		{
      name: 'estBuy',
      label: 'Estimated Buy',
      type: 'currency',
      min: 0,
      step: 0.01,
      default: undefined,
      isSortable: true,
			isTableColumn: true,
      isFormField: true,
      required: true,
      formFieldSize: 'medium',
    },
		{
      name: 'estSell',
      label: 'Estimated Sell',
      type: 'currency',
      min: 0,
      step: 0.01,
      default: undefined,
      isSortable: true,
			isTableColumn: true,
      isFormField: true,
      required: true,
      formFieldSize: 'medium',
    },
		{
      name: 'notes',
      label: 'Notes',
      type: 'textarea',
      default: '',
      isFormField: true,
      formFieldSize: 'large',
    },
		{
			name: 'createdBy',
			label: 'Created By',
			type: 'lookup-input',
      model: User,
			default: '',
      readOnly: true,
      isInfo: true,
		},
		{
			name: 'created',
			label: 'Created',
			type: 'date',
			default: '',
			readOnly: true,
			isSortable: true,
		},
    {
      name: 'updated',
      label: 'Updated',
      type: 'date',
			default: '',
			readOnly: true,
      isSortable: true,
		},
  ];

  static getRecordLabel(record: any) {
		const name = this.getFieldOptionLabel('name', record.name);
		const type = this.getFieldOptionLabel('type', record.type);
    return `${name} ― ${type}`;
  }

	static getTotals(records: GradeSchema[]): GradeTotals {
		let totalBuy: number = 0;
		let totalSell: number = 0;
		records.forEach(record => {
			totalBuy = totalBuy + (record.estBuy * record.estWeight);
			totalSell = totalSell + (record.estSell * record.estWeight);
		});
		const total: number = totalSell - totalBuy;
		return {
			buy: totalBuy,
			sell: totalSell,
			profit: total,
			variance: total / totalBuy,
		}
	}
}

export default Grade;
