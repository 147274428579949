
export function mergeRecords<T extends { id: number | string }>(newRecords: T[] = [], oldRecords: T[] = []): T[] {
	const records: T[] = [...oldRecords ];
	newRecords.forEach(newRecord => {
		const index: number = records.findIndex(r => (r.id === newRecord.id));
		if (index >= 0) {
			records[index] = newRecord;
		} else {
			records.push(newRecord);
		}
	});
	return records;
};

export function formatCurrency(n: number, isRounded: boolean = false, currency: string = 'USD'): string {
	const formatter = new Intl.NumberFormat(undefined, {
		style: 'currency',
		currency: currency,
		minimumFractionDigits: isRounded ? 0 : 2,
		maximumFractionDigits: isRounded ? 0 : 3,
	});
	return formatter.format(n);
}

export function formatWeight(n: number, decimals: number = 0, unit: string = 'lbs'): string {
	const formatter = new Intl.NumberFormat(undefined, {
		minimumFractionDigits: 0,
		maximumFractionDigits: decimals,
	});
	return `${formatter.format(n)} ${unit}`;
}

export function formatVariance(n: number, decimals: number = 0): string {
	const formatter = new Intl.NumberFormat(undefined, {
		style: 'percent',
		signDisplay: 'always',
		minimumFractionDigits: 0,
		maximumFractionDigits: decimals,
	});
	return formatter.format(n);
}

export function isAbortError(input: unknown): boolean {
	return ((input instanceof DOMException) && (input.name === 'AbortError'));
}
