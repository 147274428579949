import React from 'react';
import classNames from 'classnames';
import VarianceSpan from '../VarianceSpan/VarianceSpan';
import { LookupContext } from '../../contexts';
import { TableField, Lookup } from '../../types';
import './ActionValueByField.scss';

export interface Props<T extends Record<string, any> = Record<string, any>> {
  id?: string;
  className?: string;
  field: TableField<T>;
  value: any;
}

export interface State {

}

class ActionValueByField<T extends Record<string, any> = Record<string, any>> extends React.Component<Props<T>, State> {

  renderDefault() {
    const { value } = this.props;
    return value;
  }

  renderDate() {
    const { value } = this.props;
    return new Date(value).toLocaleString(undefined, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  }

  renderSelect() {
    const { field, value } = this.props;
    const option = field.options ? field.options.find(option => (option.value === value)) : undefined;
    return option?.label || value;
  }

  renderLookup(lookup: Lookup) {
    const { field, value } = this.props;
    let newValue = undefined;
    if (field.model) {
      const model = field.model;
      const modelOptions = model.getOptions();
      const records: any[] = modelOptions.lookupKey ? lookup[modelOptions.lookupKey] || [] : [];
      const found = records.find((record: any) => model.getRecordValue(record) === value);
      newValue = found ? model.getRecordLabel(found) : undefined;
    }
    return newValue || value;
  }

  renderNumber() {
    const { value } = this.props;
    return parseFloat(value).toLocaleString();
  }

	renderCurrency() {
    const { value } = this.props;
    return parseFloat(value).toLocaleString(undefined, { style: 'currency', currency: 'USD' });
  }

	renderNumberVariance() {
    const { value } = this.props;
    return (
			<VarianceSpan value={Number(value)} />
		);
  }

	renderCurrencyVariance() {
    const { value } = this.props;
    return (
			<VarianceSpan value={Number(value)} isCurrency={true} />
		);
  }

  renderAddress() {
    const { value } = this.props;
    let string = '';
    if (value && (typeof value === 'object')) {
      string = Object.values(value).filter(piece => Boolean(piece)).join(', ');
    }
    return ( <span>{string}</span> );
  }

  renderByType(lookup: Lookup) {
    const { field } = this.props;
    switch (field.type) {
      case 'address': return this.renderAddress();
      case 'select': return this.renderSelect();
      case 'select-input': return this.renderSelect();
      case 'date': return this.renderDate();
      case 'number': return this.renderNumber();
			case 'currency': return this.renderCurrency();
			case 'number-variance': return this.renderNumberVariance();
			case 'currency-variance': return this.renderCurrencyVariance();
      case 'lookup-input': return this.renderLookup(lookup);
      case 'lookup': return this.renderLookup(lookup);
      case 'lookup-company-section': return this.renderLookup(lookup);
      default: return this.renderDefault();
    }
  }

  render() {
    const { field, value, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-action-value-by-field', `fourg-action-value-by-field--type-${field.type}`, className);
    return (
      <LookupContext.Consumer>
        {lookup => (
          <span className={containerClass} {...restProps}>
            {this.renderByType(lookup)}
          </span>
        )}
      </LookupContext.Consumer>
    );
  }
}

export default ActionValueByField;
