import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import Table from '../../models/Table';
import { LookupContext } from '../../contexts';
import { Link } from 'react-router-dom';
import './ModelRecordInfo.scss';

export interface Props {
  id?: string;
  className?: string;
  model: typeof Table;
  value: string | number;
}

export interface State {

}

class ModelRecordInfo extends React.Component<Props, State> {

  isModelLinkable() {
    const { model } = this.props;
    const modelOptions = model.getOptions();
    switch (modelOptions.name) {
      case 'User': return false;
      case 'Notification': return false;
      case 'Contact': return false;
      case 'CieTradeLog': return false;
      case 'Report': return false;
      default: return true;
    }
  }

  hasAvatar() {
    const { model } = this.props;
    const modelOptions = model.getOptions();
    switch (modelOptions.name) {
      case 'Task': return false;
      default: return true;
    }
  }

  render() {
    const { model, value, className, ...restProps } = this.props;
    const containerClass = classNames('fourg-model-record-info', className);
    return (
      <LookupContext.Consumer>
        {lookup => {
          const { lookupKey, icon } = model.getOptions();
          const records: Record<string, any>[] = lookupKey ? lookup[lookupKey] || [] : [];
          const record = records.find(record => (model.getRecordValue(record) === value));
          if (record) {
            const image = model.getRecordImage(record);
            const label = model.getRecordLabel(record);
            return this.isModelLinkable() ? (
              <Link to={model.getRecordLink(record)} className={containerClass} {...restProps}>
                {icon && (
                  <Icon
                  className="fourg-model-record-info__icon"
                  label={label}
                  src={{ icon: icon, cover: this.hasAvatar() ? image : undefined }} />
                )}
                <span className="fourg-model-record-info__label">{label}</span>
              </Link>
            ) : (
              <div className={containerClass} {...restProps}>
                {icon && (
                  <Icon
                  className="fourg-model-record-info__icon"
                  label={label}
                  src={{ icon: icon, cover: image }} />
                )}
                <span className="fourg-model-record-info__label">{label}</span>
              </div>
            );
          }
        }}
      </LookupContext.Consumer>
    );
  }
}

export default ModelRecordInfo;
