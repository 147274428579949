import React from 'react';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import Loader from '../Loader/Loader';
import TabBar from '../TabBar/TabBar';
import TaskComments from '../TaskComments/TaskComments';
import Info from '../Info/Info';
import InfoByField from '../InfoByField/InfoByField';
import IconMessage from '../IconMessage/IconMessage';
import RecordWarnings from '../RecordWarnings/RecordWarnings';
import NewBusinessTask from '../../models/tables/tasks/NewBusinessTask';
import FinanceTask from '../../models/tables/tasks/FinanceTask';
import CustomerServiceTask from '../../models/tables/tasks/CustomerServiceTask';
import PersonalTask from '../../models/tables/tasks/PersonalTask';
import TargetTask from '../../models/tables/tasks/TargetTask';
import Task from '../../models/tables/Task';
import { LookupContext } from '../../contexts';
import { TaskSchema, ActionSchema, Lookup, Socket, SocketMessage, Auth, UIOption, UserSchema, LocationSchema, AttachmentSchema, ReminderSchema, CompanySchema, ExpenseSchema, GradeSchema, ContactSchema } from '../../types';
import { isAbortError, mergeRecords } from '../../utils';
import './TaskQuickView.scss';

export interface Props {
  id?: string;
  className?: string;
  auth: Auth;
  socket: Socket;
  recordID?: TaskSchema['id'];
  disabled?: boolean;
  onSubtaskCardClick?: (record: TaskSchema) => void;
}

export interface State {
  recordBackup?: TaskSchema;
  record?: TaskSchema;
  actions: ActionSchema[];
  totalActions: number;
  readDate?: Date;
  lookup: Lookup;
  isLoading: boolean;
  isUpdating: boolean;
  isPaging: boolean;
  page: number;
  section: string;
}

class TaskQuickView extends React.Component<Props, State> {

  static defaultProps: Partial<Props> = {

  };

	private abortController: AbortController = new AbortController();

  private comments = React.createRef<TaskComments>();

  constructor(props: Props) {
    super(props);
    this.handleCommentSubmit = this.handleCommentSubmit.bind(this);
    this.handleCommentEditSubmit = this.handleCommentEditSubmit.bind(this);
    this.handleCommentDelete = this.handleCommentDelete.bind(this);
    this.handleCommentsLoadMore = this.handleCommentsLoadMore.bind(this);
    this.handleSocketMessage = this.handleSocketMessage.bind(this);
    this.handleTabBarChange = this.handleTabBarChange.bind(this);
    const initialSection = props.auth.settings?.application?.defaultQuickViewSection || Cookies.get('quick_view_section') || 'details';
    this.state = {
      isLoading: true,
      isUpdating: false,
      isPaging: false,
      totalActions: 0,
      actions: [],
      lookup: {},
      page: 1,
      section: initialSection,
    };
  }

  componentDidMount() {
    this.readRecord();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { recordID } = this.props;
    const { section } = this.state;
    if (prevState.section !== section) {
      Cookies.set('quick_view_section', section);
    }
    if (recordID && (prevProps.recordID !== recordID)) {
      this.socketDisconnect(recordID);
      this.readRecord();
    }
  }

  componentWillUnmount() {
    const { recordID } = this.props;
		this.abortController.abort();
    if (recordID) {
      this.socketDisconnect(recordID);
    }
  }

  socketConnect(id: TaskSchema['id']) {
    const { socket } = this.props;
		if (socket.current?.readyState === WebSocket.OPEN) {
			socket.current?.send(JSON.stringify({ action: `task/${id}:connect` }));
		}
		window.addEventListener('fourg:socket-message', this.handleSocketMessage);
  }

  socketDisconnect(id: TaskSchema['id']) {
    const { socket } = this.props;
		if (socket.current?.readyState === WebSocket.OPEN) {
			socket.current?.send(JSON.stringify({ action: `task/${id}:disconnect` }));
		}
		window.removeEventListener('fourg:socket-message', this.handleSocketMessage);
  }

  handleSocketMessage(e: CustomEventInit<SocketMessage>) {
		const { auth } = this.props;
    const { record, lookup } = this.state;
    if (record && e.detail && (e.detail.type === 'task') && (e.detail.stream === `task/${record.id}`)) {
      const socketMessage = { ...e.detail } as SocketMessage<ActionSchema>;
      const { message, meta, data } = socketMessage;
      const model = this.getModelByBoard(record.board);
			const newUsers = mergeRecords<UserSchema>(meta?.users || [], auth.user ? [auth.user] : []);
      this.setState({
        record: model.getSyncedRecord(record, socketMessage),
        totalActions: this.getSyncedActionsTotal(message, data),
        actions: this.getSyncedActions(message, data),
        lookup: {
					...lookup,
          users: mergeRecords<UserSchema>(newUsers, lookup.users),
          companies: mergeRecords<CompanySchema>(meta?.companies, lookup.companies),
          locations: mergeRecords<LocationSchema>(meta?.locations, lookup.locations),
          tasks: mergeRecords<TaskSchema>(meta?.tasks, lookup.tasks),
          attachments: mergeRecords<AttachmentSchema>(meta?.attachments, lookup.attachments),
          reminders: mergeRecords<ReminderSchema>(meta?.reminders, lookup.reminders),
					grades: mergeRecords<GradeSchema>(meta?.grades, lookup.grades),
					expenses: mergeRecords<ExpenseSchema>(meta?.expenses, lookup.expenses),
					contacts: mergeRecords<ContactSchema>(meta?.contacts, lookup.contacts),
        },
      });
      const comments = this.comments.current;
      if (comments) {
        if (message === 'comment') {
          comments.scrollToStart();
        }
        if (message === 'update-comment') {
          comments.setCommentEditorValue(data, false);
        }
      }
    }
  }

  getSyncedActions(message: SocketMessage['message'], action: ActionSchema) {
    const { actions } = this.state;
    switch (message) {
      case 'update-subtask': return actions;
      case 'update-reminder': return actions;
      case 'update-attachment': return actions;
      // case 'update-grade': return actions;
      // case 'update-expense': return actions;
      case 'update-comment': return actions;
      case 'delete-comment': return this.removeAction(action);
      default:
        switch (action.action) {
          case 'update-new-business-title': return actions;
          default: return this.prependAction(action);
        }
    }
  }

  getSyncedActionsTotal(message: SocketMessage['message'], action: ActionSchema) {
    const { totalActions } = this.state;
    switch (message) {
      case 'update-subtask': return totalActions;
      case 'update-reminder': return totalActions;
      case 'update-attachment': return totalActions;
      // case 'update-grade': return totalActions;
      // case 'update-expense': return totalActions;
      case 'update-comment': return totalActions;
      case 'delete-comment': return (totalActions > 0) ? (totalActions - 1) : 0;
      default:
        switch (action.action) {
          case 'update-new-business-title': return totalActions;
          default: return (totalActions + 1);
        }
    }
  }

  async readRecord(isInitialLoad: boolean = true) {
    const { auth, socket, recordID } = this.props;
    if (recordID) {
			this.abortController.abort();
			this.abortController = new AbortController();
      this.setState({
        isLoading: isInitialLoad,
      });
      try {
        const token = await auth.getToken();
        const readDate = new Date();
        const { meta, data } = await Task.readRecord<TaskSchema>(
					token,
					recordID.toString(),
					{ created: `-${readDate.toISOString()}` },
					{ signal: this.abortController.signal }
				);
				const newUsers = mergeRecords<UserSchema>(meta?.users || [], auth.user ? [auth.user] : []);
        this.setState({
          recordBackup: data,
          record: data,
          totalActions: meta.total || 0,
          actions: data.actions?.data || [],
          readDate: readDate,
          lookup: {
            users: newUsers,
            companies: meta.companies,
            locations: meta.locations,
            tasks: meta.tasks,
            attachments: meta.attachments,
            reminders: meta.reminders,
						grades: meta.grades,
						expenses: meta.expenses,
						contacts: meta.contacts,
          },
          isLoading: false,
          page: 1,
        });
        if (isInitialLoad && (socket.current?.readyState === WebSocket.OPEN)) {
          this.socketConnect(recordID);
        }
        const comments = this.comments.current;
        comments?.scrollToStart();
      } catch(error) {
				if (! isAbortError(error)) {
					console.error(error);
					toast.error((error as Error).message);
					this.setState({
						recordBackup: undefined,
						record: undefined,
						totalActions: 0,
						actions: [],
						lookup: {},
						isLoading: false,
						page: 1,
					});
					this.socketDisconnect(recordID);
				}
      }
    }
  }

  async patchRecord(updates: Partial<TaskSchema>) {
    const { auth } = this.props;
    const { record, recordBackup } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.patchRecord<TaskSchema>(token, record.id.toString(), updates);
        toast.success(model.getLabel('updatedSingular'));
        this.setState({
          isUpdating: false,
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          recordBackup: recordBackup,
          record: recordBackup,
          isUpdating: false,
        });
      }
    }
  }

  handleCommentSubmit(value: string) {
    if (value) {
      this.createComment(value);
    }
  }

  async createComment(value: string) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.createComment(token, record.id, value);
        toast.success(model.getLabel('updatedSingular'));
        this.setState({ isUpdating: false });
        const comments = this.comments.current;
        if (comments) {
          comments.setEmptyComment();
        }
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isUpdating: false,
        });
      }
    }
  }

  async updateComment(action: ActionSchema) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.updateComment(token, record.id, action);
        toast.success(model.getLabel('updatedSingular'));
        this.setState({ isUpdating: false });
        const comments = this.comments.current;
        comments?.finishEdit();
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isUpdating: false,
        });
      }
    }
  }

  async deleteComment(action: ActionSchema) {
    const { auth } = this.props;
    const { record } = this.state;
    if (record) {
      this.setState({ isUpdating: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        await model.deleteComment(token, record.id, action);
        toast.success(model.getLabel('updatedSingular'));
        this.setState({ isUpdating: false });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          isUpdating: false,
        });
      }
    }
  }

  prependAction(newAction: ActionSchema) {
    const { actions } = this.state;
    let newActions = [...actions];
    if (actions.find(action => (action.id === newAction.id))) {
      newActions = newActions.map(action => (action.id === newAction.id) ? newAction : action);
    } else {
      newActions = [newAction, ...newActions];
    }
    return newActions;
  }

  removeAction(removedAction: ActionSchema) {
    const { actions } = this.state;
    return actions.filter(action => (action.id !== removedAction.id));
  }

  async readActions() {
    const { auth } = this.props;
    const { record, recordBackup, page, readDate, lookup, actions } = this.state;
    if (record) {
      this.setState({ isPaging: true });
      try {
        const token = await auth.getToken();
        const model = this.getModelByBoard(record.board);
        const { meta, data } = await model.readRecord<TaskSchema>(token, record.id.toString(), {
          page: (page + 1),
          created: readDate ? `-${readDate.toISOString()}` : undefined,
        });
				const newUsers = mergeRecords<UserSchema>(meta?.users || [], auth.user ? [auth.user] : []);
        this.setState({
          recordBackup: data,
          record: data,
          totalActions: meta.total || 0,
          actions: mergeRecords<ActionSchema>(data.actions?.data, actions),
          lookup: {
            ...lookup,
						users: mergeRecords<UserSchema>(newUsers, lookup.users),
						companies: mergeRecords<CompanySchema>(meta.companies, lookup.companies),
						locations: mergeRecords<LocationSchema>(meta.locations, lookup.locations),
						tasks: mergeRecords<TaskSchema>(meta.tasks, lookup.tasks),
						attachments: mergeRecords<AttachmentSchema>(meta.attachments, lookup.attachments),
						reminders: mergeRecords<ReminderSchema>(meta.reminders, lookup.reminders),
						grades: mergeRecords<GradeSchema>(meta.grades, lookup.grades),
						expenses: mergeRecords<ExpenseSchema>(meta.expenses, lookup.expenses),
						contacts: mergeRecords<ContactSchema>(meta.contacts, lookup.contacts),
          },
          isPaging: false,
          page: (page + 1),
        });
      } catch (error) {
        console.error(error);
        toast.error((error as Error).message);
        this.setState({
          recordBackup: recordBackup,
          record: recordBackup,
          isPaging: false,
        });
      }
    }
  }

  handleCommentsLoadMore() {
    this.readActions();
  }

  handleCommentEditSubmit(record: ActionSchema) {
    this.updateComment(record);
  }

  handleCommentDelete(record: ActionSchema) {
    this.deleteComment(record);
  }

  handleTabBarChange(value: UIOption['value']) {
    this.setState({ section: value });
  }

  getModelByBoard(board: string = 'peronal'): typeof Task {
    switch (board) {
      case 'targets': return TargetTask;
      case 'new-business': return NewBusinessTask;
      case 'finance': return FinanceTask;
      case 'customer-service': return CustomerServiceTask;
      default: return PersonalTask;
    }
  }

  render() {
    const { onSubtaskCardClick, disabled, auth, className, recordID, socket, ...restProps } = this.props;
    const { record, isLoading, isUpdating, isPaging, actions, totalActions, lookup, section } = this.state;
    const containerClass = classNames('fourg-task-quick-view', className);
    const model = this.getModelByBoard(record?.board);
    const taskOptions = model.getOptions<TaskSchema>();
    const fields = model.getFieldsBy<TaskSchema>('isInfo', true);
		const warnings: string[] = record ? model.getWarnings(record) : [];
    return (
      <LookupContext.Provider value={lookup}>
        <div className={containerClass} {...restProps}>
          <div className="fourg-task-quick-view__header">
            <TabBar
            disabled={(disabled || isLoading || isUpdating || isPaging)}
            value={section}
            options={[
              { value: 'details', label: 'Details' },
              { value: 'activity', label: 'Activity' },
            ]}
            onChange={this.handleTabBarChange} />
          </div>
          <div className="fourg-task-quick-view__content">
            {isLoading ? (
              <Loader position="absolute" />
            ) : ! recordID ? (
              <IconMessage
              icon={{ icon: taskOptions.icon }}
              heading={'No task selected'}
              subheading={'Select a task to view its details'} />
            ) : ! record ? (
              <IconMessage
              icon={{ icon: 'assignment_late' }}
              heading={model.getLabel('errorFetchingSingular')}
              subheading={'There was an error getting the task or it was not found.'} />
            ) : (
              <React.Fragment>
                {(section === 'details') && (
                  <div className="fourg-task-quick-view__details">
                    <div className="fourg-task-quick-view__details-scroller">
                      {record && (
												<React.Fragment>
													{warnings.length > 0 && (
														<RecordWarnings warnings={warnings} />
													)}
													<Info>
														{fields.map((field) => (
															<InfoByField<TaskSchema>
															size={field.infoSize}
															variant="quiet"
															key={`form-field-${field.name}`}
															field={field}
															value={record[field.name]} />
														))}
													</Info>
												</React.Fragment>
                      )}
                    </div>
                  </div>
                )}
                {(section === 'activity') && (
                  <div className="fourg-task-quick-view__activity">
                    <TaskComments
                    task={record}
                    ref={this.comments}
                    total={totalActions}
                    records={actions}
                    disabled={(disabled || isLoading || isUpdating || isPaging)}
                    isLoading={isPaging}
                    onLoadMore={this.handleCommentsLoadMore}
                    onCommentSubmit={this.handleCommentSubmit}
                    onCommentEditSubmit={this.handleCommentEditSubmit}
                    onCommentDelete={this.handleCommentDelete}
                    onSubtaskCardClick={onSubtaskCardClick} />
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </LookupContext.Provider>
    );
  }
}

export default TaskQuickView;
