import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import ModelRecordInfo from '../ModelRecordInfo/ModelRecordInfo';
import AutoParagraph from '../AutoParagraph/AutoParagraph';
import Badge from '../Badge/Badge';
import DateSpan from '../DateSpan/DateSpan';
import VarianceSpan from '../VarianceSpan/VarianceSpan';
import { TableField } from '../../types';
import './InfoByField.scss';

export interface Props<T extends Record<string, any> = Record<string, any>> {
  id?: string;
  className?: string;
  field: TableField<T>;
  size?: 'xsmall' | 'small' | 'medium' | 'large',
  variant?: 'default' | 'quiet';
  value: any;
	children?: React.ReactNode;
}

export interface State {

}

class InfoByField<T extends Record<string, any> = Record<string, any>> extends React.Component<Props<T>, State> {

  static defaultProps = {
    variant: 'default',
    size: 'large',
  };

  renderDefault() {
    const { value } = this.props;
    return value;
  }

  renderTextArea() {
    const { value } = this.props;
    return ( <AutoParagraph text={value} /> );
  }

  renderDate() {
    const { value } = this.props;
    const date = new Date(value);
    return (
      <DateSpan date={date} hasTime={true} hasTimeAgo={true} />
    );
  }

  renderSelect() {
    const { field, value } = this.props;
    const option = field.options ? field.options.find(option => (option.value === (value || ''))) : undefined;
    const optionLabel = option?.label || value
    return (! option?.color) ? optionLabel : (
      <Badge color={option.color}>{optionLabel}</Badge>
    );
  }

  renderSelectMultiple() {
    const { field, value } = this.props;
    let labels = '';
    if (value && field.options && (field.options.length > 0)) {
      labels = field.options
        .filter(option => value.includes(option.value))
        .map(option => option.label)
        .join(', ');
    }
    return labels;
  }

  renderNumber() {
    const { value } = this.props;
    return parseFloat(value).toLocaleString();
  }

	renderCurrency() {
    const { value } = this.props;
    return parseFloat(value).toLocaleString(undefined, { style: 'currency', currency: 'USD' });
  }

	renderNumberVariance() {
    const { value } = this.props;
    return (
			<VarianceSpan value={Number(value)} />
		);
  }

	renderCurrencyVariance() {
    const { value } = this.props;
    return (
			<VarianceSpan value={Number(value)} isCurrency={true} />
		);
  }

  renderAddress() {
    const { value } = this.props;
    let string = '';
    if (value && (typeof value === 'object')) {
      string = Object.values(value).filter(piece => Boolean(piece)).join(', ');
    }
    return ( <address>{string}</address> );
  }

  renderCheckbox() {
    const { value } = this.props;
    return (<Icon src={{ icon: value ? 'check' : 'clear' }} label={value ? 'True' : 'False'} />);
  }

  renderURL() {
    const { value } = this.props;
    return (
      <a
      target="_blank"
      rel="nofollow noopener noreferrer"
      href={value}>
        {value}
      </a>
    );
  }

  renderEmail() {
    const { value } = this.props;
    return (
      <a
      target="_blank"
      rel="nofollow noopener noreferrer"
      href={`mailto:${value}`}>
        {value}
      </a>
    );
  }

  renderTel() {
    const { value } = this.props;
    const cleanValue = (value.match(/(\+|[0-9]|x)/g) || []).join('').replace(/x/g, ',');
    return (
      <a
      target="_blank"
      rel="nofollow noopener noreferrer"
      href={`tel:${cleanValue}`}>
        {value}
      </a>
    );
  }

  renderLookup() {
    const { field, value } = this.props;
    return field.model && (
      <ModelRecordInfo
      model={field.model}
      value={value} />
    );
  }

  renderEmpty() {
    return ( <span>&#x2015;</span> );
  }

  renderByType() {
    const { field } = this.props;
    switch (field.type) {
      case 'date': return this.renderDate();
      case 'select-input': return this.renderSelect();
      case 'select': return this.renderSelect();
      case 'select-multiple': return this.renderSelectMultiple();
      case 'email': return this.renderEmail();
      case 'number': return this.renderNumber();
      case 'currency': return this.renderCurrency();
      case 'number-variance': return this.renderNumberVariance();
      case 'currency-variance': return this.renderCurrencyVariance();
      case 'address': return this.renderAddress();
      case 'checkbox': return this.renderCheckbox();
      case 'url': return this.renderURL();
      case 'tel': return this.renderTel();
      case 'lookup': return this.renderLookup();
      case 'lookup-input': return this.renderLookup();
      case 'lookup-company-section': return this.renderLookup();
      case 'lookup-location-section': return this.renderLookup();
      case 'textarea': return this.renderTextArea();
      default: return this.renderDefault();
    }
  }

  render() {
    const { size, variant, field, value, className, children, ...restProps } = this.props;
    const containerClass = classNames('fourg-info-by-field', `fourg-info-by-field--type-${field.type}`, `fourg-info-by-field--variant-${variant}`, `fourg-info-by-field--size-${size}`, className);
    return (
      <div className={containerClass} {...restProps}>
        <label className="fourg-info-by-field__label">{field.label}</label>
        <div className="fourg-info-by-field__content">
					{children ?? (
						<>{(value || (typeof value === 'number')) ? this.renderByType() : this.renderEmpty()}</>
					)}
        </div>
      </div>
    );
  }
}

export default InfoByField;
