import Table from '../Table';
import { TableOptions, TableLabels, TableField, TableEndpoints, ExpenseSchema } from '../../types';
import User from './User';

class Expense extends Table {

  static options: TableOptions = {
    name: 'Expense',
    slug: 'expenses',
    labelKey: 'name',
    valueKey: 'id',
    icon: 'attach_money',
    defaultOrder: 'created',
  };

  static labels: TableLabels = {
    description: 'All expenses in the 4G Recycling Site Manager.',
    pageTitle: 'Expense Manager',
    singular: 'Expense',
    plural: 'Expenses',
    viewSingular: 'View Expense',
    viewPlural: 'View Expenses',
    selectSingular: 'Select Expense',
    selectPlural: 'Select Expenses',
    addSingular: 'Add Expense',
    addPlural: 'Add Expenses',
    editSingular: 'Edit Expense',
    editPlural: 'Edit Expenses',
    addedSingular: 'Expense added',
    addedPlural: 'Expenses added',
    updatedSingular: 'Expense updated',
    updatedPlural: 'Expenses updated',
    deletedSingular: 'Expense deleted',
    deletedPlural: 'Expenses deleted',
    archivedSingular: 'Expense archived',
    archivedPlural: 'Expenses archived',
    restoredSingular: 'Expense restored',
    restoredPlural: 'Expenses restored',
    errorFetchingSingular: 'Error fetching Expense',
    errorFetchingPlural: 'Error fetching Expenses',
    errorAddingSingular: 'Error adding Expense',
    errorAddingPlural: 'Error adding Expenses',
    errorUpdatingSingular: 'Error updating Expense',
    errorUpdatingPlural: 'Error updating Expenses',
    errorDeletingSingular: 'Error deleting Expense',
    errorDeletingPlural: 'Error deleting Expenses',
    errorArchivingSingular: 'Error archiving Expense',
    errorArchivingPlural: 'Error archiving Expenses',
    errorRestoringSingular: 'Error restoring Expense',
    errorRestoringPlural: 'Error restoring Expenses',
    notFoundSingular: 'Expense not found',
    notFoundPlural: 'No Expenses found',
    loadingSingular: 'Loading Expense',
    loadingSingularEllipsis: 'Loading Expense...',
    loadingPlural: 'Loading Expenses',
    loadingPluralEllipsis: 'Loading Expenses...',
    search: 'Search Expenses',
    searchEllipsis: 'Search Expenses...',
    filter: 'Filter Expenses',
    settings: 'Expenses Settings',
  };

  static endpoints: TableEndpoints = {
    readRecords: 'expenses',
    readRecord: 'expense/:id',
    createRecord: 'expense',
    updateRecord: 'expense/:id',
    updateRecords: 'expenses',
    patchRecord: 'expense/:id',
    deleteRecord: 'expense/:id',
    archiveRecord: 'expense/:id/archive',
    restoreRecord: 'expense/:id/restore',
  };

  static fields: TableField[] = [
    {
      name: 'id',
      label: 'ID',
      type: 'text',
      default: '',
      readOnly: true,
    },
    {
      name: 'name',
      label: 'Name',
      type: 'select-input',
      default: 'Trailer Haul',
			options: [
				{ value: 'Trailer Haul', label: 'Trailer Haul' },
				{ value: 'Trailer Rental', label: 'Trailer Rental' },
				{ value: 'Compactor Hauling', label: 'Compactor Hauling' },
				{ value: 'Shredding', label: 'Shredding' },
				{ value: 'Baling', label: 'Baling' },
				{ value: 'Transloading', label: 'Transloading' },
				{ value: 'Compactor Rental', label: 'Compactor Rental' },
				{ value: 'Baler Rental', label: 'Baler Rental' },
			],
      required: true,
      isSortable: true,
			isTableColumn: true,
      isFormField: true,
      formFieldSize: 'large',
    },
		{
      name: 'quantity',
      label: 'Quantity',
      type: 'number',
      min: 0,
      step: 1,
      default: undefined,
      isSortable: true,
			isTableColumn: true,
      isFormField: true,
      required: true,
      formFieldSize: 'medium',
    },
		{
      name: 'estCost',
      label: 'Estimated Cost',
      type: 'currency',
      min: 0,
      step: 0.01,
      default: undefined,
      isSortable: true,
			isTableColumn: true,
      isFormField: true,
      required: true,
      formFieldSize: 'medium',
    },
		{
      name: 'notes',
      label: 'Notes',
      type: 'textarea',
      default: '',
      isFormField: true,
      formFieldSize: 'large',
    },
		{
			name: 'createdBy',
			label: 'Created By',
			type: 'lookup-input',
      model: User,
			default: '',
      readOnly: true,
		},
		{
			name: 'created',
			label: 'Created',
			type: 'date',
			default: '',
			readOnly: true,
			isSortable: true,
		},
    {
      name: 'updated',
      label: 'Updated',
      type: 'date',
			default: '',
			readOnly: true,
      isSortable: true,
		},
  ];

	static getTotals(records: ExpenseSchema[]): number {
		let total: number = 0;
		records.forEach(record => {
			total = total + (record.quantity * record.estCost);
		});
		return total;
	}
}

export default Expense;
