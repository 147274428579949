import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import { UIOption, TableFilter, Lookup } from '../../types';
import './FilterBar.scss';
import { FilterValues } from '../TaskFilters/TaskFilters';
import ChipList from '../ChipList/ChipList';
import ActionToggle from '../ActionToggle/ActionToggle';

export interface Props {
  id?: string;
  className?: string;
  lookup?: Lookup;
  searchLabel: string;
  searchValue: string;
  searchDelay: number;
	isSearchDisabled?: boolean;
  orderOptions: UIOption[];
  viewOptions: UIOption[];
  groupOptions: UIOption[];
  orderValue: UIOption['value'];
  viewValue: UIOption['value'];
  groupValue?: UIOption['value'];
  disabled?: boolean;
  isFilterOpen?: boolean;
  filterValues?: FilterValues;
  filters: TableFilter[];
  isInfoOpen?: boolean;
  onRefreshButtonClick?:(e: React.MouseEvent<HTMLButtonElement>) => void;
  onSearchChange: (search: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  onOrderChange: (order: UIOption['value'], e: React.MouseEvent<HTMLButtonElement>) => void;
  onGroupChange?: (order: UIOption['value'], e: React.MouseEvent<HTMLButtonElement>) => void;
  onViewChange: (view: UIOption['value'], e: React.MouseEvent<HTMLButtonElement>) => void;
  onInfoButtonClick?: (isInfoOpen: boolean, e: React.MouseEvent<HTMLButtonElement>) => void;
  onFilterButtonClick?: (isFilterOpen: boolean, e: React.MouseEvent<HTMLButtonElement>) => void;
  onFilterChange: (value: FilterValues, e: React.MouseEvent<HTMLButtonElement>) => void;
  onClearFiltersClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface State {
  internalSearchValue: string;
  searchTimer?: number;
}

class FilterBar extends React.Component<Props, State> {

  static defaultProps = {
    orderOptions: [],
    viewOptions: [],
    groupOptions: [],
    searchValue: '',
    orderValue: '',
    viewValue: '',
    searchDelay: 500,
    filters: [],
    onSearchChange: console.info,
    onOrderChange: console.info,
    onViewChange: console.info,
    onGroupChange: console.info,
    onFilterChange: console.info,
    onClearFiltersClick: console.info,
  };

  constructor(props: Props) {
    super(props);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchTimer = this.handleSearchTimer.bind(this);
    this.handleChipListChange = this.handleChipListChange.bind(this);
    this.state = {
      internalSearchValue: props.searchValue,
      searchTimer: undefined,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { searchValue } = this.props;
    if (prevProps.searchValue !== searchValue) {
      this.setState({ internalSearchValue: searchValue });
    }
  }

  handleSearchTimer(e: React.ChangeEvent<HTMLInputElement>) {
    const { onSearchChange } = this.props;
    const { internalSearchValue } = this.state;
    onSearchChange(internalSearchValue, e);
  }

  handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { searchDelay } = this.props;
    const { searchTimer } = this.state;
    if (searchTimer) window.clearTimeout(searchTimer);
    const newTimer = window.setTimeout(() => this.handleSearchTimer(e), searchDelay);
    this.setState({
      internalSearchValue: e.target.value,
      searchTimer: newTimer,
    });
  }

  getChipListOptions() {
    const { lookup, filters, filterValues } = this.props;
    const options: UIOption[] = [];
    if (filterValues) {
      Object.entries(filterValues).forEach(([name, value]) => {
        const filter = filters.find(filter => (filter.name === name));
        const option = filter?.options?.find(option => (option.value === value));
        let valueLabel = option?.label || value;
        if (lookup && (['lookup', 'lookup-input'].includes(filter?.type || '')) && filter?.model && ! filter.options.find(filterOption => filterOption.value === value)) {
          const modelOptions = filter.model.getOptions();
          const lookupRecords: any[] = (modelOptions.lookupKey ? lookup[modelOptions.lookupKey] || [] : []);
          const record = lookupRecords.find(record => (record.id === value));
          valueLabel = record ? filter.model.getRecordLabel(record) || valueLabel : filter.model.getLabel('loadingSingularEllipsis');
        }
        options.push({
          value: `${name}:${value}`,
          label: `${filter?.label || name}: ${valueLabel}`,
        });
      });
    }
    return options;
  }

  getChipListValue() {
    const { filterValues } = this.props;
    const chipListValue: UIOption['value'][] = [];
    if (filterValues) {
      Object.entries(filterValues).forEach(([name, value]) => {
        chipListValue.push(`${name}:${value}`);
      });
    }
    return chipListValue;
  }

  handleChipListChange(value: UIOption['value'][], e: React.MouseEvent<HTMLButtonElement>) {
    const { onFilterChange } = this.props;
    const filterValues: FilterValues = {};
    if (value.length > 0) {
      value.forEach(val => {
        const pieces = val.split(':');
        if (pieces[0] && pieces[1]) {
          filterValues[pieces[0]] = pieces[1];
        }
      });
    }
    onFilterChange(filterValues, e);
  }

  getGroupOptionLabel() {
    const { groupValue, groupOptions } = this.props;
    let label = 'Group';
    const option = groupOptions.find(groupOption => (groupOption.value === groupValue));
    if (option) {
      label = option.label;
    }
    return label;
  }

  getOrderOptionLabel() {
    const { orderValue, orderOptions } = this.props;
    let label = 'Sort';
    const option = orderOptions.find(orderOption => (orderOption.value === orderValue));
    if (option) {
      label = option.label;
    }
    return label;
  }

  render() {
    const { isSearchDisabled, groupOptions, groupValue, onGroupChange, onRefreshButtonClick, filterValues, filters, isFilterOpen, viewOptions, viewValue, disabled, orderValue, orderOptions, searchLabel, searchValue, isInfoOpen, onInfoButtonClick, onViewChange, onSearchChange, onOrderChange, onFilterButtonClick, onFilterChange, onClearFiltersClick, searchDelay, className, children, ...restProps } = this.props;
    const { internalSearchValue } = this.state;
    const containerClass = classNames('fourg-filter-bar', {
			'fourg-filter-bar--search-disabled': isSearchDisabled,
		}, className);
    return (
      <div className={containerClass} {...restProps}>
        <div className="fourg-filter-bar__start">
          <div className="fourg-filter-bar__search">
							<Icon
							className="fourg-filter-bar__search-icon"
							src={{ icon: 'search' }}
							label={searchLabel} />
							<input
							className="fourg-filter-bar__search-input"
							type="search"
							autoComplete="off"
							value={internalSearchValue}
							onChange={this.handleSearchChange}
							disabled={isSearchDisabled} />
          </div>
          {(onFilterButtonClick || (orderOptions.length > 0)) && (
            <div className="fourg-filter-bar__section">
              {onFilterButtonClick && (
                <Button
                disabled={disabled}
                icon={{ icon: 'filter_list' }}
                isActive={isFilterOpen}
                onClick={e => onFilterButtonClick(! isFilterOpen, e)}>
                  {'Filter'}
                </Button>
              )}
              {(orderOptions.length > 0) && (
                <ActionToggle
                anchor="top-right"
                disabled={disabled}
                icon={{ icon: 'sort' }}
                label={this.getOrderOptionLabel()}
                value={orderValue}
                options={orderOptions}
                onChange={onOrderChange}
                title={'Sort'} />
              )}
              {(groupOptions.length > 0) && (
                <ActionToggle
                anchor="top-right"
                disabled={disabled}
                icon={{ icon: 'dynamic_feed' }}
                label={this.getGroupOptionLabel()}
                value={groupValue || ''}
                options={groupOptions}
                onChange={onGroupChange}
                title={'Group'} />
              )}
            </div>
          )}
          {(viewOptions.length > 0) && (
            <div className="fourg-filter-bar__section">
              {viewOptions.map(viewOption => (
                <Button
                disabled={disabled}
                key={`view-option-${viewOption.value}`}
                icon={viewOption.icon}
                isActive={(viewOption.value === viewValue)}
                isIconOnly={true}
                onClick={e => onViewChange(viewOption.value, e)}>
                  {viewOption.label}
                </Button>
              ))}
            </div>
          )}
          {(onInfoButtonClick || onRefreshButtonClick) && (
            <div className="fourg-filter-bar__section">
              {onInfoButtonClick && (
                <Button
                  icon={{ icon: 'info' }}
                  isIconOnly={true}
                  disabled={disabled}
                  isActive={isInfoOpen}
                  onClick={e => onInfoButtonClick(!isInfoOpen, e)}>
                  {'Info'}
                </Button>
              )}
              {onRefreshButtonClick && (
                <Button
                isIconOnly={true}
                disabled={disabled}
                icon={{ icon: 'refresh' }}
                onClick={onRefreshButtonClick}>
                  {'Refresh'}
                </Button>
              )}
            </div>
          )}
          {children && (
            <div className="fourg-filter-bar__actions">
              {children}
            </div>
          )}
        </div>
        {(filterValues && (Object.entries(filterValues).length > 0)) && (
          <div className="fourg-filter-bar__end">
            <div className="fourg-filter-bar__filters">
              <ChipList
              disabled={disabled}
              value={this.getChipListValue()}
              options={this.getChipListOptions()}
              onChange={this.handleChipListChange} />
              <Button
              disabled={disabled}
              onClick={onClearFiltersClick}
              icon={{ icon: 'clear_all' }}>
                {'Clear'}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default FilterBar;
